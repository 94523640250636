/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import {Link} from 'gatsby';
import Portals from 'modules/common/Portals';
import PropTypes from 'prop-types';
import React from 'react';

const ConfirmationModal = ({
  dispatchAction,
  title,
  description,
  toggleConfirmationPopup,
  confirmeMessage,
  cancelMessage,
  isCertificateConfirmation,
  goToEditProfilePageMessage,
  firstName,
  lastName,
}) => {
  return (
    <Portals>
      <div className="flex items-center justify-center fixed h-100 w-100 top-0 left-0 bg-black-90 z-9999">
        <div className="h-100 w-100" onClick={toggleConfirmationPopup} />
        <div className="w5 w-80-m w-70 absolute center-absolute br2 pa4 bg-white">
          <div className="tc lh-copy">
            <h1 className="f3-ns f4">{title}</h1>
            <div className="mv3 flex justify-center">
              <p>{description}</p>&nbsp;
              {isCertificateConfirmation && (
                <span className="b">
                  {firstName} {lastName}
                </span>
              )}
            </div>
          </div>
          <div className="mt4 justify-center flex flex-row-ns flex-column">
            <button
              aria-label="Confirme message"
              onClick={dispatchAction}
              className={cx(
                'w-auto-ns w-100 pointer bw0 ph3 pv2 white f6 b br2',
                {
                  'bg-dark-red bg-animate hover-bg-red': !isCertificateConfirmation,
                  'bg-green-light bg-animate hover-bg-green': isCertificateConfirmation,
                },
              )}
            >
              {confirmeMessage}
            </button>
            {!isCertificateConfirmation ? (
              <button
                aria-label="Cancel message"
                onClick={toggleConfirmationPopup}
                className="w-auto-ns w-100 pointer bw0 ph3 pv2 bg-green-light bg-animate hover-bg-green white f6 b br2 mr3-ns mt0-ns mt2"
              >
                {cancelMessage}
              </button>
            ) : (
              <Link
                className="w-auto-ns w-100 pointer bw0 ph3 pv2 bg-dark-red bg-animate hover-bg-red white f6 b br2 mr3-ns mt0-ns mt2"
                to="/account/edit"
              >
                {goToEditProfilePageMessage}
              </Link>
            )}
          </div>
        </div>
      </div>
    </Portals>
  );
};

ConfirmationModal.propTypes = {
  toggleConfirmationPopup: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmeMessage: PropTypes.string.isRequired,
  cancelMessage: PropTypes.string,
  isCertificateConfirmation: PropTypes.bool,
  goToEditProfilePageMessage: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default ConfirmationModal;
