/* eslint import/prefer-default-export: 0 */

export const postTrackInterest = (id) => {
  return {
    types: [
      'POST_TRACK_INTEREST_REQUEST',
      'POST_TRACK_INTEREST_SUCCESS',
      'POST_TRACK_INTEREST_FAILURE',
    ],
    config: {
      url: `school/join-leave-track/${id}/interest/`,
      method: 'post',
    },
    shouldCallApi: (state) =>
      id &&
      (!state.tracks.trackInterestList[id] ||
        state.tracks.trackInterestList[id] === 'not fetched'),
    payload: {id},
  };
};
