import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import ComingSoonTrackBox from './ComingSoonTrackBox';

const ComingSoonTracksWrapper = ({title, tracks, authenticated}) => {
  if (tracks.length)
    return (
      <div className="w-100 tr">
        <div className="w-80-ns w-90 center pt5 pb0">
          <h4 className="f4 tr-ns tc black pb3 bb b--light-gray">
            <FormattedMessage id={title} />
          </h4>
        </div>
        <div className="w-80-ns w-90 center">
          <div className="flex flex-wrap">
            {tracks.map((track) => (
              <ComingSoonTrackBox
                key={track.id}
                track={track}
                authenticated={authenticated}
              />
            ))}
          </div>
        </div>
      </div>
    );
  return null;
};

ComingSoonTracksWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  tracks: PropTypes.array.isRequired,
  authenticated: PropTypes.bool,
};

export default ComingSoonTracksWrapper;
