import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const MetaItem = ({className, children, isLast, border, cy}) => {
  return (
    <div
      data-cy={cy}
      className={cx(
        'ph3-ns mv3-ns pv2-ns pv3',
        {[border]: isLast, 'bl-ns': !isLast, white: !className},
        className,
      )}>
      {children}
    </div>
  );
};

MetaItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  isLast: PropTypes.bool,
  border: PropTypes.string,
  cy: PropTypes.string,
};

export default MetaItem;
