import React from 'react';

const LeaderboardWizard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={280}
    height={156}
    viewBox="0 0 350 156">
    <defs>
      <linearGradient id="leaderboardwizard-a" y1="50%" y2="50%">
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset=".5" stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="leaderboardwizard-b" y1="50%" y2="50%">
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <path
        id="leaderboardwizard-c"
        d="M129.7 30.5c-.7-.8-1-1.9-1-3h1.7c0 .8.2 1.4.5 1.8.4.7 1.2 1 2.3 1 .8 0 1.5-.2 2-.7s.8-1 .8-1.8c0-.9-.2-1.5-.8-1.8a4.9 4.9 0 0 0-2.6-.6h-.4V24a8 8 0 0 0 .8 0c.7 0 1.2 0 1.6-.3.6-.3 1-.9 1-1.8 0-.6-.2-1.1-.7-1.5s-1-.5-1.6-.5c-1.1 0-1.9.4-2.3 1l-.4 1.8H129c0-1 .2-1.8.6-2.5.7-1.2 1.8-1.8 3.5-1.8 1.3 0 2.4.3 3 .9.8.6 1.2 1.4 1.2 2.6 0 .8-.2 1.4-.7 2-.2.2-.6.5-1 .6.7.2 1.2.6 1.6 1.1.4.6.6 1.2.6 2a4 4 0 0 1-1.2 3c-.9.8-2 1.2-3.5 1.2-1.6 0-2.7-.4-3.4-1.3z"
      />
      <linearGradient id="leaderboardwizard-d" y1="50%" y2="50%">
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <path
        id="leaderboardwizard-e"
        d="M23.2 27c.4-1 1.3-2 2.7-2.7l2-1.2c1-.5 1.6-1 2-1.4.5-.5.8-1.2.8-2s-.2-1.6-.8-2c-.5-.6-1.2-.8-2-.8-1.4 0-2.3.4-2.8 1.4-.3.6-.4 1.3-.4 2.2h-2c0-1.3.3-2.4.8-3.2.8-1.5 2.2-2.2 4.3-2.2 1.8 0 3 .5 3.8 1.4.8 1 1.2 2 1.2 3.1 0 1.3-.4 2.3-1.2 3.2-.5.5-1.4 1-2.7 1.8l-1.5.8-1.6 1a4 4 0 0 0-1.3 2.1h8.3v1.8H22.4c0-1.3.3-2.4.8-3.4z"
      />
      <path
        id="leaderboardwizard-f"
        d="M76.8 15.6V14c1.5-.2 2.6-.5 3.3-.8.6-.4 1-1.2 1.3-2.6h1.8V28h-2.4V15.6h-4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#75D3A0"
          fillRule="nonzero"
          d="M41 53c0 5.3 4.4 9.6 9.7 9.6h46.5a8 8 0 0 1 7.9 8 8 8 0 0 1-8 7.9H16.6a12.2 12.2 0 1 0 0 24.4H55a9.6 9.6 0 0 1 0 19.3h-2.7a16.6 16.6 0 0 0 0 33.3H256c6.1 0 11-5 11-11a11 11 0 0 0-11-11h-5.8a8.5 8.5 0 0 1 0-16.9H296a11.7 11.7 0 0 0 0-23.3h-34.3c-5.7 0-10.4-4.7-10.4-10.4 0-5.8 4.7-10.4 10.4-10.4h73.7a14.4 14.4 0 1 0 0-29H50.7c-5.3 0-9.6 4.2-9.6 9.5z"
        />
        <g transform="translate(203 75)">
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M3.6 19c0 .4-.2.7-.4 1s-.6.4-1 .4c-.2 0-.6-.1-.8-.4s-.4-.6-.4-1v-1.3c0-.4.1-.7.4-1s.6-.4.9-.4c.3 0 .7.2.9.4s.4.6.4 1V19z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M3.6 19c0 .4-.2.7-.4 1s-.6.4-1 .4c-.2 0-.6-.1-.8-.4s-.4-.6-.4-1v-1.3c0-.4.1-.7.4-1s.6-.4.9-.4c.3 0 .7.2.9.4s.4.6.4 1V19z"
          />
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M27 19c0 .4-.1.7-.4 1s-.6.4-.9.4c-.3 0-.7-.1-.9-.4s-.4-.6-.4-1v-1.3c0-.4.2-.7.4-1s.6-.4 1-.4l.8.4c.2.2.4.6.4 1V19z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M27 19c0 .4-.1.7-.4 1s-.6.4-.9.4c-.3 0-.7-.1-.9-.4s-.4-.6-.4-1v-1.3c0-.4.2-.7.4-1s.6-.4 1-.4l.8.4c.2.2.4.6.4 1V19z"
          />
          <path
            fill="#FCC10F"
            fillRule="nonzero"
            stroke="#424143"
            strokeWidth=".5"
            d="M28 52V41.6c0-6.6-6.3-12-14-12S0 35 0 41.6V52h28z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M.4 38.7c-.3 1-.4 2-.4 2.9V52M3.6 33.1c-1 1-2 2-2.6 3.1M28 52V41.6c0-6.6-6.3-12-14-12-2.7 0-5.5.7-7.9 2.1"
          />
          <path
            fill="#3F3F3F"
            fillRule="nonzero"
            d="M13.7 35.2c-5.9 0-10.6-4.9-10.6-10.9V12C3 5.9 7.8 1 13.7 1 19.7 1 24.4 6 24.4 12v12.4c0 6-4.8 10.9-10.7 10.9z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M13.7 35.2c-5.9 0-10.6-4.9-10.6-10.9V12C3 5.9 7.8 1 13.7 1 19.7 1 24.4 6 24.4 12v12.4c0 6-4.8 10.9-10.7 10.9z"
          />
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M14 21.1c-5 0-10 4-10 0v-7.5c0-5-.4-10 4.6-10h10c5 0 5.3 5 5.3 11.1v6.4c0 3.8-3 .3-9.9 0z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M25 20.4c0 5-5.5 1-11 1s-11 4-11-1"
          />
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M16.8 24.5a3 3 0 1 1-6.1 0h6.1z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M16.8 24.5a3 3 0 1 1-6.1 0h6.1z"
          />
          <path
            fill="#353535"
            fillRule="nonzero"
            d="M3.1 15.8c-.7 0-1.3-.3-1.8-.8-.5-.4-.8-1-.8-1.8V7c0-2.1 1.8-3.8 4-3.8 0-.9.3-1.7.9-2.3C6 .3 6.8 0 7.6 0h16c2.1 0 3.9 1.7 3.9 3.9v9.3c0 .6-.3 1.3-.8 1.7s-1.1.7-1.8.7V9.4s-1.8 2.8-7.5 2.8c-4 0-8-1-11.3-3.3-.3.7-.8 1.3-1.3 1.7-.5.5-1.1.6-1.7.5v4.7z"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M25 9.4v6.2c.6 0 1.2-.2 1.7-.7s.8-1 .8-1.7V3.9c0-2.2-1.8-3.9-4-3.9H7.7C6.8 0 6 .3 5.4 1c-.6.5-1 1.3-1 2.2C2.3 3.2.5 4.9.5 7v6.2a2.6 2.6 0 0 0 2.6 2.6v-4.7c.6.2 1.3 0 1.7-.4.6-.5 1-1 1.4-1.7"
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M4.6 8.2s6 4.5 12.7 4.5c3.2.1 6.2-1.4 8.2-4M10.7 15.3a1.9 1.9 0 0 1-2.5 0M17.3 15.8h2M12.7 14.8v4.6h4.6M5 42.8h5.7"
          />
          <ellipse
            cx="14.3"
            cy="43.8"
            stroke="#414042"
            strokeWidth=".8"
            rx={1}
            ry={1}
          />
          <ellipse
            cx="14.3"
            cy="48.4"
            stroke="#414042"
            strokeWidth=".8"
            rx={1}
            ry={1}
          />
          <path
            stroke="#414042"
            strokeWidth=".8"
            d="M13.7 41.8v-3l7.2-6.2M7.1 32.6l8.2 5.6"
          />
        </g>
        <ellipse
          cx="101.9"
          cy="4.7"
          fill="#75D3A0"
          fillRule="nonzero"
          rx={1}
          ry={1}
        />
        <ellipse
          cx="267.5"
          cy="125.7"
          fill="#75D3A0"
          fillRule="nonzero"
          rx={1}
          ry={1}
        />
        <ellipse
          cx="330.5"
          cy="87.2"
          fill="#75D3A0"
          fillRule="nonzero"
          rx={1}
          ry={1}
        />
        <ellipse
          cx="68.9"
          cy="72.3"
          fill="#75D3A0"
          fillRule="nonzero"
          rx={1}
          ry={1}
        />
        <ellipse
          cx="272.8"
          cy="85.1"
          fill="#75D3A0"
          fillRule="nonzero"
          rx={1}
          ry={1}
        />
        <ellipse
          cx="173.5"
          cy={25}
          fill="#75D3A0"
          fillRule="nonzero"
          rx="1.7"
          ry="1.7"
        />
        <ellipse
          cx="61.5"
          cy={25}
          fill="#75D3A0"
          fillRule="nonzero"
          rx="1.2"
          ry="1.2"
        />
        <ellipse
          cx="1.5"
          cy="124.2"
          fill="#75D3A0"
          fillRule="nonzero"
          rx="1.2"
          ry="1.2"
        />
        <ellipse
          cx="105.1"
          cy="31.1"
          fill="#75D3A0"
          fillRule="nonzero"
          rx="1.5"
          ry="1.5"
        />
        <path
          fill="#75D3A0"
          fillRule="nonzero"
          d="M192.6 2.5c-1.5.4-1.9.7-2.3 2.2-.4-1.4-.7-1.8-2.3-2.2 1.5-.5 1.9-.7 2.3-2.3.4 1.5.7 1.8 2.3 2.3zM322.6 117c-1.5.4-1.8.7-2.1 2.1-.4-1.3-.7-1.7-2.1-2 1.4-.4 1.7-.7 2-2.1.4 1.3.8 1.7 2.2 2zM50.3 115.2c-1.8.5-2.2.9-2.7 2.7-.5-1.8-.9-2.2-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.5 1.8.9 2.2 2.7 2.7zM296.5 81.3c-2.3.6-2.9 1.2-3.5 3.5-.6-2.3-1.1-2.9-3.5-3.5 2.4-.6 3-1.1 3.5-3.5.7 2.4 1.2 3 3.5 3.5zM252.2 19.2c-1.8.5-2.3.9-2.7 2.7-.5-1.8-.9-2.3-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.4 1.8.9 2.3 2.7 2.7zM31.8 60.6c-2 .5-2.4 1-2.9 2.9-.5-2-1-2.4-2.9-3 2-.4 2.4-.9 2.9-2.8.5 2 .9 2.4 2.9 2.9z"
        />
      </g>
      <path
        stroke="#2A292A"
        strokeWidth="1.2"
        d="M192.4 126.7h53.3v27.5h-53.3z"
      />
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M192.4 127.4h53.3v2.3h-53.2z"
        opacity=".8"
      />
      <path
        fill="#3F3E40"
        fillRule="nonzero"
        d="M192.4 126.7h53.3v2.3h-53.3z"
      />
      <path
        fill="url(#leaderboardwizard-a)"
        fillRule="nonzero"
        d="M107.4 10.7h53.3v1h-53.3z"
        style={{mixBlendMode: 'overlay'}}
        transform="translate(85 116)"
      />
      <path
        fill="#aaa"
        fillRule="nonzero"
        d="M192.4 126.7h53.3v27.5h-53.3z"
        style={{mixBlendMode: 'soft-light'}}
      />
      <path
        fill="url(#leaderboardwizard-b)"
        fillRule="nonzero"
        d="M107.4 10.7h15.5v27.5h-15.5z"
        opacity=".6"
        style={{mixBlendMode: 'soft-light'}}
        transform="translate(85 116)"
      />
      <path
        fill="url(#leaderboardwizard-b)"
        fillRule="nonzero"
        d="M107.4 10.7h1.4v27.5h-1.4z"
        opacity=".5"
        style={{mixBlendMode: 'soft-light'}}
        transform="translate(85 116)"
      />
      <g transform="translate(85 116)">
        <use fill="#000" xlinkHref="#leaderboardwizard-c" />
        <use fill="#3E3D3F" xlinkHref="#leaderboardwizard-c" />
      </g>
      <path
        stroke="#383739"
        strokeWidth="1.2"
        d="M85.8 120.9h53.3v33.4H85.8z"
      />
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M85.8 121.5H139v2.3H85.8z"
        opacity=".8"
      />
      <path fill="#3F3E40" fillRule="nonzero" d="M85.8 120.9h53.3v2.3H85.8z" />
      <path
        fill="url(#leaderboardwizard-a)"
        fillRule="nonzero"
        d="M.8 4.9h53.3v1H.8z"
        style={{mixBlendMode: 'overlay'}}
        transform="translate(85 116)"
      />
      <path
        fill="#aaa"
        fillRule="nonzero"
        d="M85.8 120.9h53.3v33.4H85.8z"
        style={{mixBlendMode: 'soft-light'}}
      />
      <path
        fill="url(#leaderboardwizard-d)"
        fillRule="nonzero"
        d="M36.3 4.9h17.8v33.4H36.3z"
        opacity=".6"
        style={{mixBlendMode: 'soft-light'}}
        transform="translate(85 116)"
      />
      <path
        fill="url(#leaderboardwizard-d)"
        fillRule="nonzero"
        d="M52.2 4.9h1.9v33.4h-1.9z"
        opacity=".5"
        style={{mixBlendMode: 'soft-light'}}
        transform="translate(85 116)"
      />
      <g transform="translate(85 116)">
        <use fill="#000" xlinkHref="#leaderboardwizard-e" />
        <use fill="#3E3D3F" xlinkHref="#leaderboardwizard-e" />
      </g>
      <path
        stroke="#403F41"
        strokeWidth="1.2"
        d="M139.1 116h53.3v38.2h-53.3z"
      />
      <path fill="#414042" fillRule="nonzero" d="M139.1 116h53.3v2.3h-53.3z" />
      <path
        fill="url(#leaderboardwizard-a)"
        fillRule="nonzero"
        d="M54.1 0h53.3v1H54.1z"
        style={{mixBlendMode: 'overlay'}}
        transform="translate(85 116)"
      />
      <path
        fill="#aaa"
        fillRule="nonzero"
        d="M139.1 116h53.3v38.2h-53.3z"
        style={{mixBlendMode: 'soft-light'}}
      />
      <g transform="translate(85 116)">
        <use fill="#000" xlinkHref="#leaderboardwizard-f" />
        <use fill="#3E3D3F" xlinkHref="#leaderboardwizard-f" />
      </g>
      <g transform="translate(92 67.2)">
        <path
          fill="#424242"
          fillRule="nonzero"
          d="M39.4 18c0-1.2-.6-2-1.3-2.6.8-.5 1.3-1.4 1.3-2.5a3 3 0 0 0-3.8-2.8l.2-1a3 3 0 0 0-3-3h-.5a3 3 0 0 0-4.4-2.5A2.9 2.9 0 0 0 23.6 2 3 3 0 0 0 21 .2 3 3 0 0 0 18.2 2a3 3 0 0 0-1.7-.5c-1.1 0-2.2.6-2.6 1.7A3 3 0 0 0 9 5.5v.2l-1-.1a3 3 0 0 0-3 2.9l.1.5H5a3 3 0 0 0-2.9 3c0 .7.3 1.4.8 2a3 3 0 0 0-2.4 2.8c0 1.1.6 2 1.5 2.5A3 3 0 0 0 0 22.1c0 1 .5 1.9 1.3 2.4A3 3 0 0 0 0 26.8a3 3 0 0 0 3 3h.2c-.1.3-.3.7-.3 1.2a3 3 0 0 0 3 2.9h.2l-.2.7a3 3 0 0 0 3 3v.5a3 3 0 1 0 5.8 0 18.4 18.4 0 0 0 10.2-.2 3 3 0 0 0 2.7 1.7 3 3 0 0 0 2.9-3 3 3 0 0 0 3-2.7l.4.1a3 3 0 0 0 2.9-3c0-.5-.2-1.2-.6-1.6a3 3 0 0 0 1-4.7c1.1-.4 2-1.5 2-2.8a3 3 0 0 0-1-2.1c.7-.3 1-1 1-1.9z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M39.4 18c0-1.2-.6-2-1.3-2.6.8-.5 1.3-1.4 1.3-2.5a3 3 0 0 0-3.8-2.8l.2-1a3 3 0 0 0-3-3h-.5a3 3 0 0 0-4.4-2.5A2.9 2.9 0 0 0 23.6 2 3 3 0 0 0 21 .2 3 3 0 0 0 18.2 2a3 3 0 0 0-1.7-.5c-1.1 0-2.2.6-2.6 1.7A3 3 0 0 0 9 5.5v.2l-1-.1a3 3 0 0 0-3 2.9l.1.5H5a3 3 0 0 0-2.9 3c0 .7.3 1.4.8 2a3 3 0 0 0-2.4 2.8c0 1.1.6 2 1.5 2.5A3 3 0 0 0 0 22.1c0 1 .5 1.9 1.3 2.4A3 3 0 0 0 0 26.8a3 3 0 0 0 3 3h.2c-.1.3-.3.7-.3 1.2a3 3 0 0 0 3 2.9h.2l-.2.7a3 3 0 0 0 3 3v.5a3 3 0 1 0 5.8 0 18.4 18.4 0 0 0 10.2-.2 3 3 0 0 0 2.7 1.7 3 3 0 0 0 2.9-3 3 3 0 0 0 3-2.7l.4.1a3 3 0 0 0 2.9-3c0-.5-.2-1.2-.6-1.6a3 3 0 0 0 1-4.7c1.1-.4 2-1.5 2-2.8a3 3 0 0 0-1-2.1c.7-.3 1-1 1-1.9z"
        />
        <path
          fill="#D2E9F7"
          fillRule="nonzero"
          d="M33.5 53v-2.3c0-7.6-6.3-13.9-13.9-13.9S5.8 43.1 5.8 50.7v2.2h27.7z"
        />
        <path
          fill="#F8CC1C"
          fillRule="nonzero"
          d="M33.5 53v-2.3c0-7.6-6.3-13.9-13.9-13.9S5.8 43.1 5.8 50.7v2.2h27.7z"
        />
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M27.7 39.5a13.7 13.7 0 0 0-16.6.4c0 2.4 1.9 4.3 4.2 4.3h8.1c2.4 0 4.3-2 4.3-4.3v-.4z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M27.7 39.5a13.7 13.7 0 0 0-16.6.4c0 2.4 1.9 4.3 4.2 4.3h8.1c2.4 0 4.3-2 4.3-4.3v-.4z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M10.7 40.2c2.4-2 5.5-3.4 9-3.4 7.5 0 13.8 6.3 13.8 13.9v2.2M7.5 44.2c.5-.8.9-1.5 1.5-2.2M6 53v-2.3c0-1 0-2.2.3-3.2"
        />
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M19.5 30a3 3 0 0 0-3 3v6.4h6v-6.3a3 3 0 0 0-3-3z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M22.4 39.4v-6.3a3 3 0 1 0-6 0v6.3"
        />
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M30.7 29.9l.9-10.6c0-8-3.8-9-8.5-9h-8c-4 0-7.1 3.4-7.1 7.6l1 12c0 1.2.7 2.2 1.7 2.8l7.6 4c.7.3 1.5.3 2.2 0l8.3-4c1-.6 1.8-1.6 1.9-2.8z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M30.7 30.1l.9-11.2c0-7.7-3.8-8.5-8.5-8.5h-8c-4 0-7.1 3.2-7.1 7.1l1 12.6a3 3 0 0 0 1.7 2.6l7.6 3.7c.7.4 1.5.4 2.2 0l8.3-3.8c1-.3 1.8-1.3 1.9-2.5z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M24.3 29.8l-.9.7c-.5.5-1.2.7-2 .7h-3.7c-.7 0-1.4-.2-2-.7l-.8-.7"
        />
        <path
          fill="#424242"
          fillRule="nonzero"
          d="M29.8 11a3 3 0 0 0-2 .7c-.4-.5-1.2-.8-2-.8a3 3 0 0 0-2 .8 3 3 0 0 0-2-.8 3 3 0 0 0-2.6 1.5c-.4-.3-1-.5-1.6-.5a3 3 0 0 0-1.8.6 3 3 0 0 0-5.2-1c-.5-.5-1-.6-1.7-.6a3 3 0 0 0-3 2.9 3 3 0 0 0 5.3 1.8c.5.4 1 .6 1.7.6s1.3-.3 1.8-.7a3 3 0 0 0 5.4.8 3 3 0 0 0 3.7-.3 3 3 0 0 0 2 .8 3 3 0 0 0 2-.8c.6.5 1.3.8 2 .8a3 3 0 0 0 3-3 3 3 0 0 0-3-2.9z"
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M6 14a3 3 0 0 0 5.3 1.7c.5.4 1 .6 1.7.6s1.3-.3 1.8-.6a3 3 0 0 0 5.4.7c.4.3 1 .5 1.6.5a3 3 0 0 0 2-.8 3 3 0 0 0 2.1.8 3 3 0 0 0 2-.8 3 3 0 0 0 5-2.1"
        />
        <ellipse
          cx="21.5"
          cy="10.4"
          fill="#414141"
          fillRule="nonzero"
          rx="12.1"
          ry={3}
        />
        <path
          stroke="#414042"
          strokeWidth=".9"
          d="M10.7 15.3a3 3 0 0 1 2.1-5.1M19.8 10.7c-.5-.6-.8-1.3-.8-2.2s.3-1.5.8-2c.6-.6 1.3-1 2.2-1M12.9 20.8c.5-.6 1.2-.9 2-.9a3 3 0 0 1 2.2.9M23.1 20.8a3 3 0 0 1 4.2 0M1 20c.6-.7 1.2-1 2.1-1a3 3 0 0 1 3 3 3 3 0 0 1-.9 2.1M31.9 6.3a3 3 0 0 0-2 1c-.5.6-.9 1.3-.8 2.1a3 3 0 0 0 1 2M33.7 30c-.5.6-.8 1.3-.7 2.1s.4 1.6 1 2.1M8.9 5.6a3 3 0 0 1 0 4.2M8.9 33.5a3 3 0 0 1 0 4.2M38.6 20.5c-.7-.2-1.5-.2-2.3.2a3 3 0 0 0-1.5 1.6M21.3 13.7a3 3 0 0 1-.8 2M18.5 23.6v3.1h2.6v-1"
        />
      </g>
      <g transform="translate(146 61.2)">
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          stroke="#404041"
          strokeWidth=".5"
          d="M11.5 22A10 10 0 0 1 3 12.4V8.9h8.6V6H0v6.4c0 6.5 5 12 11.5 12.8v-3z"
        />
        <path
          fill="#F9A11B"
          fillRule="nonzero"
          stroke="#404041"
          strokeWidth=".5"
          d="M0 8.7V12c0 6.4 5 11.8 11.5 12.5v-2.9C6.6 20.9 3 16.8 3 12V8.7H0z"
        />
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          stroke="#404041"
          strokeWidth=".5"
          d="M28.5 22a10 10 0 0 0 8.6-9.7V8.9h-8.6V6H40v6.4c0 6.5-5 12-11.5 12.8v-3z"
        />
        <path
          fill="#F9A11B"
          fillRule="nonzero"
          stroke="#404041"
          strokeWidth=".5"
          d="M40 8.7V12c0 6.4-5 11.8-11.5 12.5v-2.9c4.9-.7 8.6-4.8 8.6-9.6V8.7H40z"
        />
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          stroke="#424143"
          strokeWidth=".5"
          d="M23.5 32.8l-.6-3.8h.1c.5 0 1-.4 1-.9s-.5-.9-1-.9h-5.5a1 1 0 0 0-.9 1c0 .4.4.8 1 .8l-.5 3.8c-2 .2-3.4 1.8-3.4 3.6h13.1c0-1.8-1.4-3.4-3.3-3.6z"
        />
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          stroke="#424143"
          strokeWidth=".5"
          d="M19.8 28.3h-.2c-6.4 0-11.8-4.8-12.5-11.1L5.5 3.9H34l-1.6 13.3a12.6 12.6 0 0 1-12.6 11.1z"
        />
        <path
          fill="#E1A725"
          fillRule="nonzero"
          d="M22.5 30.5l-.3-1.6h-4.4l-.3 1.6z"
        />
        <path
          fill="#FFF0A3"
          fillRule="nonzero"
          d="M10.4 19.6c-.6-1.8-1-3.7-1.1-5.6l-.5-3.9c0-.4-.6-.4-.6 0l.5 4.2c.1 2 .5 3.9 1.2 5.7.1.4.6 0 .5-.4zM13 23.5l-1.4-1.6c-.3-.3-.8 0-.6.3l1.6 1.7c.3.2.7-.2.5-.4z"
        />
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          d="M30 19.2c-1.2 2.8-3.5 5-6.3 6.3-.4.2 0 .8.4.6 2.9-1.3 5.2-3.6 6.5-6.5.2-.4-.4-.8-.5-.4zM31 15.6l-.3 1.4c-.1.4.7.6.8.2l.3-1.6c0-.5-.8-.5-.8 0z"
        />
        <path
          fill="#FFF0A3"
          fillRule="nonzero"
          d="M16 33.8c-.4.2-.8.6-1.1 1-.3.3.1.8.4.5l1-.9c.3-.2 0-.8-.3-.6z"
        />
        <path
          fill="#E1A725"
          fillRule="nonzero"
          d="M34 3.3L33.8 5H6.2L6 3.3h28z"
        />
        <path
          fill="#FCC10F"
          fillRule="nonzero"
          stroke="#424143"
          strokeWidth=".5"
          d="M34.4 3.8H5.6C4.6 3.8 4 3 3.8 2c0-.5.2-1 .5-1.3.4-.4.9-.6 1.3-.6h28.8c.4 0 1 .2 1.3.6s.5.8.5 1.3c0 1-.9 2-1.8 2z"
        />
        <path
          fill="#FFF1BA"
          fillRule="nonzero"
          d="M7 2.2h-.9c-.3 0-.6-.3-.6-.6s.3-.5.6-.5h1c.3 0 .6.2.6.5s-.3.6-.7.6zM14.1 2.2H9.4c-.3 0-.6-.3-.6-.6s.3-.5.6-.5h4.7l.5.1.2.4c0 .2 0 .3-.2.4l-.5.2z"
        />
        <path
          fill="#7D7C9A"
          fillRule="nonzero"
          stroke="#424143"
          strokeWidth=".5"
          d="M33.6 49.3v-9.7a3 3 0 0 0-3-3.1H9a3 3 0 0 0-3.1 3v9.8h-.2a3 3 0 0 0-3 3v1h34v-1a3 3 0 0 0-3-3h-.1z"
        />
        <path fill="#5E5F7C" fillRule="nonzero" d="M6.6 47.4h26.8V49H6.6z" />
        <path fill="#403F5B" fillRule="nonzero" d="M2.7 53.4h34V55h-34z" />
        <rect
          width="14.8"
          height="7.6"
          x="12.6"
          y="39.8"
          fill="#94A4A6"
          fillRule="nonzero"
          rx=".8"
        />
        <rect
          width="14.8"
          height="7.6"
          x="12.6"
          y="39.2"
          fill="#BDC9CB"
          fillRule="nonzero"
          rx=".8"
        />
        <path
          fill="#D9E5E6"
          fillRule="nonzero"
          d="M27.4 39.5a1 1 0 0 0-.7-.3H26l-7.5 7.6h1.6l7.2-7.3zM13.2 46.7l.4.1h3.2l7.3-7.6h-3.7l-7.2 7.5z"
        />
        <path
          fill="#7C8C8D"
          fillRule="nonzero"
          d="M15.2 41.4h9c.3 0 .5-.3.5-.6s-.2-.5-.5-.5h-9c-.2 0-.4.2-.4.5s.2.6.4.6zM16.9 43h5.7c.2 0 .4-.2.4-.5s-.2-.6-.4-.6h-5.7c-.3 0-.5.3-.5.6s.2.5.5.5zM15.2 45.2h9c.3 0 .5-.2.5-.5s-.2-.6-.5-.6h-9c-.2 0-.4.3-.4.6s.2.5.4.5z"
        />
        <path stroke="#E38025" strokeWidth="1.1" d="M19.7 9.8v6" />
        <path stroke="#E38025" strokeWidth="1.6" d="M22 9.8h-4M22 15.8h-4" />
      </g>
      <path stroke="#75D3A0" strokeWidth="1.3" d="M.3 154.2h321" />
    </g>
  </svg>
);

export default LeaderboardWizard;
