import React from 'react';

const WelcomeWizard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="280px"
    height="156px"
    viewBox="0 0 350 156">
    <g fill="none" fillRule="evenodd">
      <g fill="#BCBEC0" fillRule="nonzero" transform="translate(18)">
        <ellipse cx="101.9" cy="4.7" rx="1" ry="1" />
        <ellipse cx="267.5" cy="125.7" rx="1" ry="1" />
        <ellipse cx="330.5" cy="87.2" rx="1" ry="1" />
        <ellipse cx="68.9" cy="72.3" rx="1" ry="1" />
        <ellipse cx="272.7" cy="85.1" rx="1" ry="1" />
        <ellipse cx="173.5" cy="25" rx="1.7" ry="1.7" />
        <ellipse cx="61.5" cy="25" rx="1.2" ry="1.2" />
        <ellipse cx="1.5" cy="124.2" rx="1.2" ry="1.2" />
        <ellipse cx="105.1" cy="31.1" rx="1.5" ry="1.5" />
        <path d="M192.6 2.5c-1.5.4-1.9.7-2.3 2.2-.4-1.4-.7-1.8-2.3-2.2 1.5-.5 1.9-.7 2.3-2.3.4 1.5.7 1.8 2.3 2.3zM322.5 117c-1.3.4-1.7.7-2 2.1-.4-1.4-.7-1.7-2.1-2 1.4-.4 1.7-.7 2-2.1.4 1.4.8 1.7 2.1 2zm-272.2-1.8c-1.8.5-2.2.9-2.7 2.7-.5-1.8-.9-2.2-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.5 1.8.9 2.2 2.7 2.7zm246.2-33.9c-2.3.6-2.9 1.2-3.5 3.5-.6-2.3-1.1-2.9-3.4-3.5 2.3-.6 2.8-1.1 3.4-3.5.7 2.4 1.3 3 3.5 3.5zm-44.3-62.1c-1.8.5-2.3.9-2.7 2.7-.5-1.8-.9-2.3-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.4 1.8.9 2.3 2.7 2.7zM31.8 60.6c-2 .5-2.4 1-2.9 2.9-.5-2-1-2.4-2.9-3 2-.4 2.4-.9 2.9-2.8.5 2 .9 2.4 2.9 2.9z" />
      </g>
      <path
        fill="#BABABA"
        d="M133 37H43.9a8.5 8.5 0 0 0 0 17.1H64a5 5 0 0 1 5 5.2 5 5 0 0 1-5 5.1H17.5a7.6 7.6 0 1 0 0 15.3h63.4a6 6 0 0 1 6 6 6 6 0 0 1-6 6H6.2A6.2 6.2 0 0 0 0 98v.8c0 3.4 2.8 6.2 6.2 6.2H303a8.6 8.6 0 0 0 0-17.3h-21.1a6.2 6.2 0 0 1-6.2-6.2c0-3.4 2.8-6.2 6.2-6.2h44.3a7.8 7.8 0 0 0 0-15.6h-93.1a5 5 0 0 1-5-5 5 5 0 0 1 5-5.1h61c3.4 0 6.2-2.8 6.2-6.2 0-3.5-2.8-6.2-6.2-6.2h-161V37zm-39.7 92H21.7a7.6 7.6 0 0 1-7.7-7.5c0-4.1 3.4-7.5 7.7-7.5h71.6c4.3 0 7.7 3.4 7.7 7.5s-3.4 7.5-7.7 7.5m249.1 2h-54.8a7.5 7.5 0 0 1-7.6-7.5c0-4.1 3.4-7.5 7.6-7.5h54.8a7.5 7.5 0 1 1 0 15"
        opacity=".2"
      />
      <g transform="translate(126.8 11.2)">
        <g stroke="#414042">
          <path
            fill="#FFF"
            fillRule="nonzero"
            strokeWidth=".9"
            d="M15.2 22.3c-.6 0-1.1.5-1.1 1.1v2.1l-2.7 1.9V14c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v-.5c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3V15c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v1.4c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v12.5c0 .4.1.7.3.9l1.4 1.3c.2.2.3.5.3.8v3h8.8l-.1-3.5 2.4-1.9 2.2-1.6c.2-.2.3-.5.3-.7v-3.8c-.2-.7-.8-1.1-1.4-1.1z"
          />
          <path
            strokeWidth="1.3"
            d="M6 20.8v-7.2c0-.7.7-1.3 1.4-1.3s1.3.6 1.3 1.3v7.2m-5.3 2.4V15c0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v8.2m-2.6-.6v-6.2c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v12.5c0 .4.2.7.3.9l1.4 1.3c.2.2.4.5.4.8v3m8.5 0v-3.5l2.4-1.9L16 28l.4-.7v-3.8c0-.7-.6-1.2-1.2-1.2s-1.1.5-1.1 1.2v2l-2.7 1.9V14c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v9"
          />
        </g>
        <path
          fill="#6E7074"
          fillRule="nonzero"
          d="M55.4 49C47 49 39.9 53 36 58.8H15.7a3.1 3.1 0 0 1-3.2-3.1V34H1.8v21.6c0 7.6 6.2 13.8 13.8 13.8h17.6V141h44.3V68c0-10.4-10-19-22.1-19z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M55.4 49C47 49 39.9 53 36 58.8H15.7a3.1 3.1 0 0 1-3.2-3.1V34H1.8v21.6c0 7.6 6.2 13.8 13.8 13.8h17.6V141h44.3V68c0-10.4-10-19-22.1-19z"
        />
        <path
          fill="#6D6E71"
          fillRule="nonzero"
          d="M66.2 23l8.1-6.2v7.7c0 1.6-1.3 2.9-3 2.7l-5.1-.5V23z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M74.3 16.8v7.5c0 1.8-1.3 3.2-3.2 3.2H68L66.2 23l8.1-6.2z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M41.7 33.2c0 1.1-1 2-2 2-1.2 0-2.2-.9-2.2-2v-2c0-1.1 1-2.1 2.1-2.1s2.1 1 2.1 2v2.1z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M41.7 33.2c0 1.1-1 2-2 2-1.2 0-2.2-.9-2.2-2v-2c0-1.1 1-2.1 2.1-2.1s2.1 1 2.1 2v2.1z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M74.3 33.2c0 1.1-1 2-2 2a2 2 0 0 1-2.1-2v-2c0-1.1 1-2.1 2-2.1a2 2 0 0 1 2.1 2v2.1z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M74.3 33.2c0 1.1-1 2-2 2a2 2 0 0 1-2.1-2v-2c0-1.1 1-2.1 2-2.1a2 2 0 0 1 2.1 2v2.1z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M67.7 63.5l-9.5 3c-1.4.5-3 .5-4.4 0l-9.5-3a4.4 4.4 0 0 1-3.1-4.3V23.5a11 11 0 0 1 10.9-10.9H60c6 0 10.9 5 10.9 11v35.6c0 1.9-1.3 3.7-3.2 4.3z"
        />
        <path
          fill="#6D6E71"
          fillRule="nonzero"
          d="M67.7 63.5l-9.5 3c-1.4.5-3 .5-4.4 0l-9.5-3a4.4 4.4 0 0 1-3.1-4.3V43c1 .6 2.2.6 3.3 0l6.5-3c1.1-.1 2.4.3 3.1 1.1L56 43h.2l1.8-2c.8-.8 2-1.2 3.1-1l6.2 2.9c1.1.5 2.4.5 3.6 0v16.2c0 1.9-1.3 3.7-3.2 4.3z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M41.2 43c1 .6 2.2.6 3.3 0l6.5-3c1.1-.1 2.4.3 3.1 1.1L56 43h.2l1.8-2c.8-.8 2-1.2 3.1-1l6.2 2.9c1.1.5 2.4.5 3.6 0"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M67.7 63.5l-9.5 3c-1.4.5-3 .5-4.4 0l-9.5-3a4.4 4.4 0 0 1-3.1-4.3V23.5a11 11 0 0 1 10.9-10.9H60c6 0 10.9 5 10.9 11v35.6c0 1.9-1.3 3.7-3.2 4.3z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M52.2 46.4H60a4 4 0 0 1-4 3.9c-2.8 0-3.8-4-3.8-4z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M60 46.4c0 2.1-1.7 3.9-3.8 3.9a4 4 0 0 1-4-4h7.9z"
        />
        <path
          fill="#6D6E71"
          fillRule="nonzero"
          d="M41.2 27.9a12 12 0 0 1 4.8-9.6l4.4-3.3 18 5.1A9.5 9.5 0 0 0 79 16.6c2.1-3.2 2.4-8.3-5.6-16 0 1.1 1 8.6-4.3 7.6 0 0-11.4-2-19.6-2.1a5.5 5.5 0 0 0-5.7 5.6v.3h-3.1a3 3 0 0 0-3 3v9.8c.1 1.8 1.5 3 3.2 3h.4z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M41.2 27.9a12 12 0 0 1 4.8-9.6l4.4-3.3 18 5.1A9.5 9.5 0 0 0 79 16.6c2.1-3.2 2.4-8.3-5.6-16 0 1.1 1 8.6-4.3 7.6 0 0-11.4-2-19.6-2.1a5.5 5.5 0 0 0-5.7 5.6v.3h-3.1a3 3 0 0 0-3 3v9.8c.1 1.8 1.5 3 3.2 3h.4zm6-14.5l6.2 2.4m4.1 9v11.5h-5M46.4 26h7M48 27.5h3m9.9 1.6h3M59.2 26h7m2.4 52.9v62m-12.5.1V72.2l-6.6-7"
        />
        <ellipse
          cx="53.4"
          cy="77.1"
          stroke="#414042"
          strokeWidth="1.3"
          rx="1.1"
          ry="1.1"
        />
        <ellipse
          cx="53.4"
          cy="82.9"
          stroke="#414042"
          strokeWidth="1.3"
          rx="1.1"
          ry="1.1"
        />
        <ellipse
          cx="53.4"
          cy="88.8"
          stroke="#414042"
          strokeWidth="1.3"
          rx="1.1"
          ry="1.1"
        />
        <ellipse
          cx="53.4"
          cy="94.7"
          stroke="#414042"
          strokeWidth="1.3"
          rx="1.1"
          ry="1.1"
        />
        <ellipse
          cx="53.4"
          cy="100.5"
          stroke="#414042"
          strokeWidth="1.3"
          rx="1.1"
          ry="1.1"
        />
      </g>
      <path
        fill="#A7A9AC"
        fillRule="nonzero"
        d="M210 155.2h-60c-1.4 0-2.6-1-2.8-2.4l-.4-2.6h27.7l1 1.5h7.7l1.2-1.5h29.2l-.3 2.2a3.3 3.3 0 0 1-3.3 2.8z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M210 155.2h-60c-1.4 0-2.6-1-2.8-2.4l-.4-2.6h27.7l1 1.5h7.7l1.2-1.5h29.2l-.3 2.2a3.3 3.3 0 0 1-3.3 2.8z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M210.1 152.8h-59.6a.9.9 0 0 1-.9-.9v-42.3c0-.6.4-1 .9-1h59.6c.6 0 .9.4.9 1v42.3c0 .5-.3.9-.9.9z"
      />
      <g fill="#4A4A4A" fillRule="nonzero">
        <path d="M163.2 129.1h-.6c-.8 0-1.2.3-1.2 1.1v.6h-.3c-.8 0-1.1.4-1.1 1.2v1.2c0 .2 0 .3.3.3.2 0 .3 0 .3-.3V132c0-.4.2-.6.5-.6h.2l.2.1c0 .6.4.9 1.1.9h.6c.8 0 1.1-.4 1.1-1.1v-1c0-.9-.3-1.2-1.1-1.2zm.5 2.2c0 .3-.2.5-.5.5h-.6c-.3 0-.5-.2-.5-.5v-1c0-.4.2-.6.5-.6h.6c.3 0 .5.2.5.5v1zm7.3-2.3h-.7v-.1l.8-1 .1-.3-.1-.2c-.2-.1-.3-.1-.4 0l-1.3 1.7-.2.2c0 .3.1.3.3.3h1.4c.4 0 .6.2.6.6v1.5l-.1.1H168l-.1-.1v-1.5c0-.8-.4-1.1-1.2-1.1h-.5c-.8 0-1.2.3-1.2 1v1.2c0 .7.4 1 1.2 1h1v.2c0 .2 0 .4-.5.4h-.3c-.2 0-.3.1-.3.3 0 .2 0 .3.3.3h.3c.8 0 1.1-.3 1.2-1l.1-.1h3.7c.3 0 .4 0 .4-.3v-1.9c0-.8-.4-1.1-1.2-1.1zm-3.7 2.7l-.1.1h-1c-.4 0-.6-.2-.6-.5v-1.1c0-.4.2-.6.5-.6h.6c.4 0 .6.2.6.6v1.5zm26.7-2.6h-.6c-.8 0-1.2.4-1.2 1.1v1.5l-.1.1h-12.8v-1c0-1.1-.5-1.7-1.5-1.7h-1.6c-.2 0-.3 0-.3.3 0 .2.1.3.3.3h1.6c.4 0 .9.2.9 1.2v.8l-.2.1h-2.7c-.2 0-.3.1-.3.3v.3c0 .7.4 1.1 1.2 1.1.3 0 .3 0 .3-.3 0-.2 0-.3-.3-.3-.4 0-.5-.2-.5-.4l.1-.1H194c.8 0 1.2-.3 1.2-1.1v-1c0-.8-.4-1.2-1.2-1.2zm.5 2.2c0 .3-.2.5-.5.5h-1.1v-1.6c0-.3.1-.5.5-.5h.6c.3 0 .5.2.5.5v1zm5.5-1.9v2.7c0 .2-.1.3-.3.3h-2.8l-.2.1c0 .7-.4 1-1.1 1h-.3c-.3 0-.3 0-.4-.3 0-.2.1-.3.4-.3h.3c.3 0 .5-.2.5-.5v-3c0-.2.1-.3.3-.3.3 0 .3 0 .3.3v2.3l.2.1h2.3l.2-.1v-2.3c0-.2 0-.3.3-.3.2 0 .3 0 .3.3zm-25.9 2.4v.2l-.1.1h-.5v-.4l.1-.1h.5v.2z" />
        <path d="M179.2 133.4v.2h-.7v-.4l.2-.1h.3l.1.1v.2zm20.8 0v.2h-.7v-.4l.2-.1h.3l.1.1v.2zm-39.2 2.8h-.5v-.4l-.2-.1-.1.1v1.7l.1.2h.7c.4 0 .5-.2.5-.5v-.5c0-.3-.1-.5-.5-.5zm.2 1c0 .1 0 .2-.2.2h-.5v-1h.5c.1 0 .2.1.2.3v.5zm4.3-1.1h-.5c-.3 0-.5.2-.5.6v.8l.1.2c.1 0 .2 0 .2-.2v-.3h.6v.3l.1.2c.1 0 .2 0 .2-.2v-1.2c0-.1 0-.2-.2-.2zm0 .8h-.7v-.2c0-.2 0-.3.2-.3h.4v.5zm4.4-.8h-.5c-.4 0-.5.2-.5.6v1c.2 0 .2 0 .2-.2v-.8c0-.2.1-.3.3-.3h.3v.2l.2.1.1-.1v-.3l-.1-.2zm4.6 0H173l-.1.2v1.2l.1.2c.1 0 .2 0 .2-.2v-1h.3c.2 0 .3 0 .3.2v.8l.1.2.1-.2v-1h.4c.2 0 .3 0 .3.2v.8l.1.2.1-.2v-.8c0-.4-.1-.6-.5-.6zm4.5 1.3h-.5c-.2 0-.3 0-.3-.2v-.2h.7l-.1-.2h-.6v-.4h.8l.1-.1v-.2h-1l-.1.2v.8c0 .4.1.6.5.6h.5l.1-.2zm3.9-1h-.1l-.2.1.1.2v1.1l-.1.3h-.1l-.2.1.2.1c.4 0 .5-.1.5-.5v-1.4zm.1-.4h-.2v.1h.1v.1l.1-.2zm7.5 1.4h-.6c-.1 0-.2-.1-.2-.3v-.4c0-.2 0-.3.2-.3h.5l.2-.1c0-.1 0-.2-.2-.2h-.5c-.3 0-.5.2-.5.6v.5c0 .3.2.5.5.5h.6v-.3zm3.9-1.3h-.2c-.3 0-.4.2-.4.6v.5c0 .3.1.5.4.5h.3c.3 0 .4-.2.4-.6v-.4c0-.4-.1-.6-.5-.6zm.3 1c0 .2-.1.3-.3.3h-.2c-.1 0-.2 0-.2-.2v-.5c0-.2 0-.3.2-.3h.2c.2 0 .3.1.3.3v.5zm4.9-1h-1.3c-.1 0-.2 0-.2.2v1.2l.1.2c.2 0 .2 0 .2-.2v-1h.4c.1 0 .2 0 .2.2v.8l.1.2c.1 0 .2 0 .2-.2v-1h.4c.1 0 .2 0 .2.2v.8l.1.2c.1 0 .2 0 .2-.2v-.8c0-.4-.2-.6-.6-.6z" />
      </g>
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M186.9 108.7h4.2m4.9 0h13.5c.9 0 1.6.7 1.6 1.6v41c0 .9-.7 1.6-1.6 1.6h-58.3c-.9 0-1.6-.7-1.6-1.6v-41c0-1 .7-1.6 1.6-1.6h31.4"
      />
      <g transform="translate(99 81.3)">
        <path
          fill="#3D3A44"
          fillRule="nonzero"
          d="M55.1 25c0-1.5-.8-2.7-1.8-3.5A4 4 0 0 0 55 18a4 4 0 0 0-5.3-3.9A4 4 0 0 0 46 8.5l-.8.1A4 4 0 0 0 41 4.3c-.8 0-1.5.2-2 .6a4 4 0 0 0-6-2.2c-.5-1.4-2-2.4-3.7-2.4a4 4 0 0 0-3.9 2.6 4 4 0 0 0-6 1.7 4 4 0 0 0-2.6-1 4 4 0 0 0-4 4.2V8a4 4 0 0 0-5.5 3.8v.7h-.2A4 4 0 0 0 3 16.6c0 1 .4 2 1 2.8-1.8.5-3.3 2.1-3.3 4 0 1.6.9 3 2 3.6a4.2 4.2 0 0 0-2.5 3.8 4 4 0 0 0 1.7 3.4 4 4 0 0 0 2.4 7.4h.3c-.2.5-.3 1-.3 1.6a4 4 0 0 0 4 4.1h.3l-.2 1a4 4 0 0 0 4.1 4.2h.1v.6a4 4 0 1 0 8.1 0A25.9 25.9 0 0 0 35 53a4 4 0 0 0 3.7 2.3 4 4 0 0 0 4.1-4 4 4 0 0 0 4.1-3.9h.6a4 4 0 0 0 4.1-4c0-.9-.2-1.8-.8-2.4a4.2 4.2 0 0 0 1.3-6.5 4 4 0 0 0 2.8-4c0-1-.5-2.1-1.3-2.9 1-.4 1.5-1.5 1.5-2.6z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M55.1 25c0-1.5-.8-2.7-1.8-3.5A4 4 0 0 0 55 18a4 4 0 0 0-5.3-3.9A4 4 0 0 0 46 8.5l-.8.1A4 4 0 0 0 41 4.3c-.8 0-1.5.2-2 .6a4 4 0 0 0-6-2.2c-.5-1.4-2-2.4-3.7-2.4a4 4 0 0 0-3.9 2.6 4 4 0 0 0-6 1.7 4 4 0 0 0-2.6-1 4 4 0 0 0-4 4.2V8a4 4 0 0 0-5.5 3.8v.7h-.2A4 4 0 0 0 3 16.6c0 1 .4 2 1 2.8-1.8.5-3.3 2.1-3.3 4 0 1.6.9 3 2 3.6a4.2 4.2 0 0 0-2.5 3.8 4 4 0 0 0 1.7 3.4 4 4 0 0 0 2.4 7.4h.3c-.2.5-.3 1-.3 1.6a4 4 0 0 0 4 4.1h.3l-.2 1a4 4 0 0 0 4.1 4.2h.1v.6a4 4 0 1 0 8.1 0A25.9 25.9 0 0 0 35 53a4 4 0 0 0 3.7 2.3 4 4 0 0 0 4.1-4 4 4 0 0 0 4.1-3.9h.6a4 4 0 0 0 4.1-4c0-.9-.2-1.8-.8-2.4a4.2 4.2 0 0 0 1.3-6.5 4 4 0 0 0 2.8-4c0-1-.5-2.1-1.3-2.9 1-.4 1.5-1.5 1.5-2.6z"
        />
        <path
          fill="#D2E9F7"
          fillRule="nonzero"
          d="M46.8 73.9v-3.2c0-10.6-8.7-19.3-19.3-19.3A19.4 19.4 0 0 0 8.2 70.7V74h38.6z"
        />
        <path
          fill="#7EABFF"
          fillRule="nonzero"
          d="M46.8 73.9v-3.2c0-10.6-8.7-19.3-19.3-19.3A19.4 19.4 0 0 0 8.2 70.7V74h38.6z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M38.8 55.2a19.2 19.2 0 0 0-23.3.6 6 6 0 0 0 6 5.9h11.3a6 6 0 0 0 6-6v-.5z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M38.8 55.2a19.2 19.2 0 0 0-23.3.6 6 6 0 0 0 6 5.9h11.3a6 6 0 0 0 6-6v-.5z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M15 56.1c3.4-2.9 7.7-4.7 12.5-4.7 10.6 0 19.3 8.7 19.3 19.3V74M10.5 61.7l2-3M8.3 73.9v-3.2c0-1.4.1-3 .5-4.4"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M27.2 42c-2.3 0-4.1 2-4.1 4.2V55h8.4v-9c0-2.3-2-4.1-4.3-4.1z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M31.4 55v-8.8a4.2 4.2 0 1 0-8.3 0V55"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M43 41.7L44.1 27c.1-11.3-5.3-12.5-11.9-12.5h-11c-5.6 0-10 4.7-10 10.5l1.3 16.8a5 5 0 0 0 2.5 3.9L25.7 51c1 .6 2 .6 3 0l11.6-5.5a4.6 4.6 0 0 0 2.6-3.9z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M43 42l1.2-15.6c.1-10.8-5.3-12-11.9-12H21.2a10 10 0 0 0-10 10l1.3 17.7c.2 1.6 1 3 2.5 3.6l10.7 5.2c1 .4 2 .4 3 0l11.6-5.3A4 4 0 0 0 43 42z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M34 41.6l-1.2 1c-.8.6-1.7 1-2.8 1h-5.2c-1 0-2-.4-2.8-1l-1.1-1"
        />
        <path
          fill="#3D3A44"
          fillRule="nonzero"
          d="M41.8 15.2c-1 0-2 .5-2.8 1.2a4 4 0 0 0-2.8-1.2c-1.1 0-2 .5-2.9 1.2a4.2 4.2 0 0 0-2.8-1.2c-1.6 0-2.9 1-3.6 2.1-.6-.4-1.4-.7-2.3-.7-1 0-1.8.4-2.5 1-.4-2-2-3.2-4-3.2a4 4 0 0 0-3.2 1.5 4 4 0 0 0-2.5-.7 4 4 0 1 0 0 8.2 4 4 0 0 0 3.3-1.6 4 4 0 0 0 2.4.8c1 0 1.8-.4 2.5-1a4.1 4.1 0 0 0 7.6 1.1c.6.5 1.4.7 2.3.7 1.1 0 2-.4 2.8-1.1.8.7 1.8 1.1 3 1.1 1 0 2-.4 2.7-1.1a4 4 0 0 0 2.8 1.1 4 4 0 1 0 0-8.2z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M8.4 19.4a4 4 0 0 0 7.4 2.6c.7.5 1.4.7 2.4.7s1.8-.3 2.5-.8a4.1 4.1 0 0 0 7.6 1c.6.5 1.4.7 2.3.7 1 0 2-.4 2.8-1.1a4.2 4.2 0 0 0 5.7 0 4 4 0 0 0 2.8 1.1 4 4 0 0 0 4-4"
        />
        <ellipse
          cx="30"
          cy="14.5"
          fill="#3D3A44"
          fillRule="nonzero"
          rx="17"
          ry="4.2"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M15 21.3a4 4 0 0 1-1.2-3 4.2 4.2 0 0 1 4.1-4.1m9.9.7a4 4 0 0 1-1.2-3 4 4 0 0 1 1.2-3 4 4 0 0 1 3-1.1M18 29a4 4 0 0 1 3-1.2c1.1 0 2.2.4 3 1.2m8.4 0a4.2 4.2 0 0 1 5.9 0M1.4 27.8a4 4 0 0 1 3-1.2 4.1 4.1 0 0 1 4.1 4.2c0 1-.4 2.1-1.2 3m37.4-25c-1.2 0-2.2.6-3 1.4a4 4 0 0 0-1 3 5 5 0 0 0 1.4 2.8m5.1 26a4 4 0 0 0-1 2.9c0 1.1.5 2.1 1.3 2.8M12.4 7.8a4 4 0 0 1 1.3 3c0 1.1-.5 2.2-1.3 3m0 33a4 4 0 0 1 1.3 3c0 1-.5 2.1-1.3 3M54 28.7c-1-.4-2.1-.3-3.2.1-1 .6-1.7 1.4-2 2.4M29.9 19a4 4 0 0 1-1.3 3m-2.7 10.9v4.4h3.6v-1.4"
        />
      </g>
      <path stroke="#F4F4F4" strokeWidth="1.3" d="M18.3 155.2h321" />
    </g>
  </svg>
);

export default WelcomeWizard;
