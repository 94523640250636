import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {customIndex, container, listItem} from './styles.scss';

const TrackLine = ({
  handleChangeTrack,
  recommendedTracks,
  currentIndex,
  tracksProgress,
}) => (
  <div className="ph5-l ph2-m">
    {recommendedTracks.length > 1 && (
      <div className="pv2 ph4-ns ph1 tc">
        <div className="flex items-center mb3">
          {recommendedTracks.map(({id, name}, index) => (
            <div key={id} className="flex1">
              <button
                aria-label="Change track"
                className={cx(
                  'ph3 dim silver f6-ns f7 fw6 bn bg-transparent pointer outline-0',
                  {
                    blue: tracksProgress[id] !== 'not started',
                  },
                )}
                name={index}
                onClick={handleChangeTrack}
              >
                {name}
              </button>
            </div>
          ))}
        </div>
        <div className="cf" />
        <div className={`flex items-center ${container}`}>
          {recommendedTracks.map(({id}, index) => (
            <div key={id} className={`flex1 ${listItem}`}>
              <button
                aria-label="Change track"
                className={cx(
                  'pa0 fw5 bw0 bg-animate pointer outline-0 br-pill',
                  customIndex,
                  {
                    'bg-button-blue hover-bg-dark-blue b--light-blue white':
                      currentIndex !== index &&
                      tracksProgress[id] !== 'not started',
                    'bg-near-white hover-bg-moon-gray b--silver silver':
                      tracksProgress[id] === 'not started',
                    'bg-dark-blue white': currentIndex === index,
                  },
                )}
                onClick={handleChangeTrack}
                name={index}
              >
                {index + 1}
              </button>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

TrackLine.propTypes = {
  handleChangeTrack: PropTypes.func.isRequired,
  recommendedTracks: PropTypes.array.isRequired,
  tracksProgress: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,
};

export default TrackLine;
