import React from 'react';

const TracksWizard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="280px"
    height="156px"
    viewBox="0 0 350 156">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#6F9FFA"
          d="M41 53c0 5.3 4.4 9.6 9.7 9.6h46.5a8 8 0 0 1 7.9 8 8 8 0 0 1-8 7.9H16.6a12.2 12.2 0 1 0 0 24.3H55a9.7 9.7 0 0 1 0 19.4h-2.7a16.6 16.6 0 0 0 0 33.3H256c6.1 0 11-5 11-11a11 11 0 0 0-11-11h-5.8a8.5 8.5 0 0 1 0-16.9H296a11.7 11.7 0 0 0 0-23.3h-34.3a10.4 10.4 0 1 1 0-20.8h73.7a14.4 14.4 0 1 0 0-29l-284.6.1a9.3 9.3 0 0 0-9.6 9.4z"
        />
        <ellipse cx="101.9" cy="4.7" fill="#BCBEC0" rx="1" ry="1" />
        <ellipse cx="267.5" cy="125.7" fill="#BCBEC0" rx="1" ry="1" />
        <ellipse cx="330.5" cy="87.2" fill="#BCBEC0" rx="1" ry="1" />
        <ellipse cx="68.9" cy="72.3" fill="#BCBEC0" rx="1" ry="1" />
        <ellipse cx="272.7" cy="85.1" fill="#BCBEC0" rx="1" ry="1" />
        <ellipse cx="173.5" cy="25" fill="#BCBEC0" rx="1.7" ry="1.7" />
        <ellipse cx="61.5" cy="25" fill="#BCBEC0" rx="1.2" ry="1.2" />
        <ellipse cx="1.5" cy="124.2" fill="#BCBEC0" rx="1.2" ry="1.2" />
        <ellipse cx="105.1" cy="31.1" fill="#BCBEC0" rx="1.5" ry="1.5" />
        <path
          fill="#BCBEC0"
          d="M192.6 2.5c-1.5.4-1.9.7-2.3 2.2-.4-1.4-.7-1.8-2.3-2.2 1.5-.5 1.9-.7 2.3-2.3.4 1.5.7 1.8 2.3 2.3zM322.5 117c-1.3.4-1.7.7-2 2.1-.4-1.4-.7-1.7-2.1-2 1.4-.4 1.7-.7 2-2.1.4 1.4.8 1.7 2.1 2zm-272.2-1.8c-1.8.5-2.2.9-2.7 2.7-.5-1.8-.9-2.2-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.5 1.8.9 2.2 2.7 2.7zm246.2-33.9c-2.3.6-2.9 1.2-3.5 3.5-.6-2.3-1.1-2.9-3.4-3.5 2.3-.6 2.8-1.1 3.4-3.5.7 2.4 1.3 3 3.5 3.5zm-44.3-62.1c-1.8.5-2.3.9-2.7 2.7-.5-1.8-.9-2.3-2.7-2.7 1.8-.5 2.3-.9 2.7-2.7.4 1.8.9 2.3 2.7 2.7zM31.8 60.6c-2 .5-2.4 1-2.9 2.9-.5-2-1-2.4-2.9-3 2-.4 2.4-.9 2.9-2.8.5 2 .9 2.4 2.9 2.9z"
        />
      </g>
      <path
        fill="#231F20"
        fillRule="nonzero"
        d="M183.2 129h.7v-3.2c0-3.3-1-6.5-2.3-9.3a6 6 0 0 0 2.4-4.7V73.2c-2 3-3.1 6.6-3.1 10.4V129l2.3-.1zm-40.4 0v-11.7a23 23 0 0 0-1.5 4.1l-.6-.2c-.4 1.5-.5 3-.5 4.6v3.2h2.6zm0-12.1V82.3c0-3.5-1-6.7-2.9-9.6v39.1a6 6 0 0 0 2.9 5z"
        opacity=".3"
      />
      <path
        fill="#FFB936"
        fillRule="nonzero"
        d="M176.3 117h-28.7a5.2 5.2 0 0 1-5.3-5.2V62.6c0-2.9 2.4-5.2 5.3-5.2h28.7c2.9 0 5.2 2.3 5.2 5.2v49.2c0 2.9-2.3 5.2-5.2 5.2z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M176.3 117h-28.7a5.2 5.2 0 0 1-5.3-5.2V62.6c0-2.9 2.4-5.2 5.3-5.2h28.7c2.9 0 5.2 2.3 5.2 5.2v49.2c0 2.9-2.3 5.2-5.2 5.2z"
      />
      <path
        fill="#00D4FF"
        fillRule="nonzero"
        d="M181.4 129v-3.2c0-10.7-8.8-19.4-19.4-19.4a19.5 19.5 0 0 0-19.4 19.4v3.2h38.8z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M156.8 125.2h10c4.8 0 7.5-3.4 7.3-8.8v-5.7c-2.8-2.2-6-3.7-9.7-4.1h-5.5c-3.5.7-6.7 2.2-9.3 4.4v5.9a7.3 7.3 0 0 0 7.2 8.3z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M156.8 125.2h10c4.8 0 7.5-3.4 7.3-8.8v-5.7c-2.8-2.2-6-3.7-9.7-4.1h-5.5c-3.5.7-6.7 2.2-9.3 4.4v5.9a7.3 7.3 0 0 0 7.2 8.3z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M149.3 111.1a19.5 19.5 0 0 1 32.1 14.7v3.2m-36.5-12.3c.6-1 1.2-2 2-3m-4.4 15.3v-3.2c0-1.5.2-3 .5-4.4"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M161.7 114.3c-2.4 0-4.3-2-4.3-4.3v-9a4.3 4.3 0 0 1 8.5 0v9c0 2.3-1.9 4.3-4.2 4.3z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M157.4 111.6v-10.5a4.3 4.3 0 0 1 8.5 0v10.5"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M168.7 104.3l-2.8 1.2h-8.4l-2.8-1.2a14 14 0 0 1-8.4-12.7V74.7h.7c5.3 0 10.3-2.5 13.4-6.9l1.6-2.3 1.5 2c3.1 4.5 8.2 7.2 13.6 7.2v17c0 5.5-3.3 10.5-8.4 12.6z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M177 74.7v16.9c0 5.6-3.2 10.6-8.3 12.7l-2.8 1.2h-8.4l-2.8-1.2a14 14 0 0 1-8.4-12.7V74.7m-2.4 0h3c5.4 0 10.4-2.5 13.5-6.9l1.6-2.3 1.5 2c3.1 4.5 8.2 7.2 13.6 7.2h2.7"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M160.1 78.3v13.5h3l.8-1.3m-7.4-9.6c-.5.5-1.2.9-2 .9s-1.5-.4-2.2-1m18.6.1a3 3 0 0 1-2.1.9c-.8 0-1.5-.4-2-1M157 98.4h5.9c1.5 0 3-.7 3.9-1.8m-5.1 21.6v7.2"
      />
      <g transform="translate(128 108.3)">
        <path
          fill="#A7A9AC"
          fillRule="nonzero"
          d="M64 46.9H4c-1.4 0-2.6-1-2.8-2.5l-.4-2.6h27.8l1 1.6h7.7l1-1.6h29.3l-.3 2.3a3.3 3.3 0 0 1-3.3 2.8z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M64 46.9H4c-1.4 0-2.6-1-2.8-2.5l-.4-2.6h27.8l1 1.6h7.7l1-1.6h29.3l-.3 2.3a3.3 3.3 0 0 1-3.3 2.8z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M64.2 44.4H4.5c-.5 0-.8-.3-.8-.8V1.2c0-.5.3-.9.8-.9h59.7c.5 0 .8.4.8 1v42.3c0 .5-.3.8-.8.8z"
        />
        <path
          stroke="#414042"
          strokeWidth="1.3"
          d="M41 .3h4m5 0h13.6C64.4.3 65 1 65 2v41c0 1-.7 1.6-1.5 1.6H5.2c-.8 0-1.5-.7-1.5-1.5V2C3.7 1 4.4.2 5.2.2h31.5"
        />
        <ellipse
          cx="34.4"
          cy="22.4"
          fill="#F1F2F2"
          fillRule="nonzero"
          rx="6.5"
          ry="6.5"
        />
      </g>
      <g fill="#FFF" fillRule="nonzero">
        <path d="M200.5 154.3c-.8 0-1.4-.6-1.4-1.4v-3c0-.9.6-1.5 1.4-1.5.8 0 1.4.6 1.4 1.4v3.1c0 .8-.7 1.4-1.4 1.4zm4.5 0c-.9 0-1.5-.6-1.5-1.4v-3c0-.9.6-1.5 1.4-1.5.8 0 1.4.6 1.4 1.4v3.1c0 .8-.7 1.4-1.4 1.4z" />
        <path d="M202.7 155c-.8 0-1.4-.7-1.4-1.4v-3.8c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4v3.8c0 .7-.6 1.4-1.4 1.4zm28-.7c-.8 0-1.4-.6-1.4-1.4v-3c0-.9.6-1.5 1.4-1.5.8 0 1.4.6 1.4 1.4v3.1c0 .8-.7 1.4-1.4 1.4zm4.4 0c-.7 0-1.4-.6-1.4-1.4v-3c0-.9.7-1.5 1.4-1.5.8 0 1.4.6 1.4 1.4v3.1c0 .8-.7 1.4-1.4 1.4z" />
        <path d="M232.9 155c-.8 0-1.4-.7-1.4-1.4v-3.8c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4v3.8c0 .7-.6 1.4-1.4 1.4z" />
      </g>
      <path
        fill="#D2E9F7"
        fillRule="nonzero"
        d="M236.8 154.5h-29a2.4 2.4 0 0 1-2.3-2.4v-22.5c0-.9.7-1.5 1.4-1.5h29.9v26.4z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M215.7 154.5H208c-1.3 0-2.4-1-2.4-2.3v-22.6c0-.8.7-1.5 1.5-1.5h29.8"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M237.3 154.5H208c1.5 0 2.3-1.2 2.3-2.3v-26c0-.9.7-1.6 1.4-1.6h25.6c.8 0 1.5.7 1.5 1.5v27.1c0 .7-.6 1.3-1.4 1.3z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M222.9 124.5h14.3c.8 0 1.5.7 1.5 1.5v27.1c0 .8-.6 1.4-1.4 1.4H208c1.5 0 2.3-1.2 2.3-2.3v-26c0-.9.7-1.6 1.4-1.6h3m2.3-.1h4m-7.4 10.1h21.6m-13.5 4.2h13.4m-13.4 2.8h13.4m-13.4 2.9h13.4m-21.5 2.8h21.5m-21.5 2.7h21.5"
      />
      <path
        fill="#FFB936"
        fillRule="nonzero"
        d="M213.7 138h7v7h-7zm-95-2.2H99.9v5.6c0 4 1.5 8 4.3 11v2.8h10.2v-2.9c2.7-3 4.3-6.8 4.3-10.9v-5.6z"
      />
      <path
        fill="#FFD586"
        fillRule="nonzero"
        d="M114.7 135.8v5.6c0 4-1.5 8-4.3 11v2.8h2.2v-2.9c2.7-3 4.2-6.8 4.2-10.9v-5.6h-2.1z"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M118.1 145c.3-1.2.4-2.3.4-3.6v-5.6H99.8v5.6c0 4 1.4 8 4.2 11v2.8h10.2v-2.9l1.5-1.8m.7-1c.5-1 1-2 1.3-3"
      />
      <path
        stroke="#414042"
        strokeWidth="1.3"
        d="M100.7 146.5h-3a4 4 0 0 1-4-4 4 4 0 0 1 4-4h2m7-4.8v-1.1a3 3 0 0 1 3-3h2.8c.6 0 1-.5 1-1 0-.7-.5-1-1-1h-8.7a1 1 0 0 1-1-1c0-.6.4-1 1-1h3.6m1.6.1h3.1c.6 0 1.2-.5 1.2-1.2 0-.6-.6-1-1.2-1h-8c-.8 0-1.3-.6-1.3-1.3 0-.7.5-1.2 1.2-1.2h11.8"
      />
      <path stroke="#6F9FFA" strokeWidth="1.3" d="M.3 155.2h321" />
      <path
        fill="#FFB936"
        d="M237 116.4c4.8 0 8.7 3.9 8.7 8.7 0 4.8-3.9 8.6-8.6 8.6a8.6 8.6 0 0 1 0-17.3z"
      />
      <path
        fill="#E0EBC5"
        d="M230 125c0-4.5 3.5-8.1 7.8-8.6h-.7a8.6 8.6 0 0 0 0 17.3h.7a8.7 8.7 0 0 1-7.8-8.6z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M235.8 128.2l-2.4-2.2.4-.5 2 1.9 4.2-4.3.5.4z"
      />
      <path
        fill="#333441"
        fillRule="nonzero"
        d="M237 117a8 8 0 0 1 8 8 8 8 0 1 1-16 0 8 8 0 0 1 8-8zm0-.6a8.6 8.6 0 0 0 .1 17.3c4.7 0 8.6-3.8 8.6-8.6s-3.9-8.7-8.6-8.7z"
      />
    </g>
  </svg>
);

export default TracksWizard;
