/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import onboardingSteps from 'data/onboardingSteps.json';
import Portals from 'modules/common/Portals';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {compose, withHandlers, withProps, withStateHandlers} from 'recompose';
import closeIconWhite from 'theme/assets/images/close-icon-white.svg';
import closeIcon from 'theme/assets/images/close-icon.svg';
import NewDesignButton from '../NewDesignButton';
import OnboardingIcons from './OnboardingIcons';
import {
  forceBlack,
  heightBox,
  hintCard,
  overlay,
  pvButton,
  stepDot,
} from './styles.scss';

const OnboardingHint = ({
  id,
  nextStep,
  first_name,
  changeStateUserToOld,
  goToStep,
  isOnboardingVisible,
  isNewUser,
}) => (
  <div>
    {isOnboardingVisible && isNewUser === 'false' && (
      <Portals>
        <div className={`z-1 fixed h-100 w-100 left-0 top-0 ${overlay}`}>
          <div
            className={`br2 bg-white w-25-ns w-50-m w-90 overflow-hidden shadow-4 absolute ${hintCard}`}
          >
            <div
              className="pa2 bg-white"
              style={{
                backgroundColor: (
                  onboardingSteps.find((item) => item.id === id) || {}
                ).backgroundColor,
              }}
            >
              <div className="tl">
                <img
                  onClick={changeStateUserToOld}
                  className="pointer grow"
                  src={id === 0 ? closeIcon : closeIconWhite}
                  alt="close wizard hint"
                />
              </div>
              <div className="tc pa3">
                <OnboardingIcons id={id} />
              </div>
            </div>
            <div className="ph3 pv1 tc">
              {id === 0 ? (
                <div className="pv3">
                  <h3 className="pb1" data-cy="welcome-onboarding-hint-title">
                    <FormattedMessage id="welcome_only" /> {first_name}،{' '}
                    <FormattedMessage id="onboarding_hints_welcome" />
                  </h3>
                  <p className="mid-gray mb3 mt4 lh-copy mh3">
                    <FormattedMessage id="onboarding_hints_welcome_description" />
                  </p>
                </div>
              ) : (
                <div className={`mh3 mt4 ${heightBox}`}>
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="tr" data-cy="step-onboarding-hint-title">
                        {
                          (onboardingSteps.find((item) => item.id === id) || {})
                            .title
                        }
                      </h4>
                    </div>
                    <div className="flex items-center">
                      {onboardingSteps.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => goToStep(item.id)}
                          className={cx('br-pill pointer', stepDot, {
                            'bg-green-light': item.id === id,
                            'bg-near-white': item.id !== id,
                          })}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="pb4 pt3">
                    <p className="mv3 lh-copy mid-gray tr">
                      {
                        (onboardingSteps.find((item) => item.id === id) || {})
                          .description
                      }
                    </p>
                  </div>
                </div>
              )}
              {id === 0 && (
                <NewDesignButton
                  className={`bg-green-light hover-bg-green mb4 ph4 ${pvButton}`}
                  onClick={nextStep}
                  cy="lets-go"
                >
                  <FormattedMessage id="lets_go" />
                </NewDesignButton>
              )}
              {id < 4 && id !== 0 && (
                <div className="mb4 flex items-center justify-center">
                  <NewDesignButton
                    className={`bg-green-light ml2 hover-bg-green ph3 ${pvButton}`}
                    onClick={nextStep}
                    cy="next"
                  >
                    <FormattedMessage id="continue" />
                  </NewDesignButton>
                  <NewDesignButton
                    onClick={changeStateUserToOld}
                    className={`bg-near-white bg-animate hover-bg-moon-gray ba b--moon-gray mr2 ph3 ${forceBlack}`}
                  >
                    <FormattedMessage id="close" />
                  </NewDesignButton>
                </div>
              )}
              {id === 4 && (
                <NewDesignButton
                  className={`bg-green-light hover-bg-green mb4 ph4 ${pvButton}`}
                  onClick={changeStateUserToOld}
                  cy="start-learning-code"
                >
                  <FormattedMessage id="start_learning_code" />
                </NewDesignButton>
              )}
            </div>
          </div>
        </div>
      </Portals>
    )}
  </div>
);

OnboardingHint.propTypes = {
  id: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  first_name: PropTypes.string,
  goToStep: PropTypes.func.isRequired,
  isOnboardingVisible: PropTypes.bool.isRequired,
  changeStateUserToOld: PropTypes.func.isRequired,
  isNewUser: PropTypes.string,
};

const enhance = compose(
  withStateHandlers(
    ({initialStep = 0}) => ({
      id: initialStep,
      isOnboardingVisible: true,
    }),
    {
      hideOnboarding: () => () => ({
        isOnboardingVisible: false,
      }),
      nextStep: ({id}) => (value) => ({
        id: id + 1,
      }),
      goToStep: ({id}) => (value) => ({
        id: value,
      }),
    },
  ),

  connect(({user}) => ({
    first_name: user.profile.data.first_name,
    isProfileFetched: user.profile.fetched,
  })),
  spinnerWhileLoading(
    ({isProfileFetched, nextStep}) => !isProfileFetched && !nextStep,
  ),
  withHandlers({
    changeStateUserToOld: ({hideOnboarding}) => () => {
      hideOnboarding();
      // eslint-disable-next-line no-unused-expressions
      typeof window !== 'undefined' &&
        window.localStorage &&
        window.localStorage.setItem('hide_onboarding_hint', true);
    },
  }),
  withProps(() => ({
    isNewUser:
      typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.getItem('hide_onboarding_hint'),
  })),
);

export default enhance(OnboardingHint);
