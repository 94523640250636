import decodeId from 'core/libs/helpers/decodeId';
import redirectIf from 'core/libs/hoc/redirectIf';
import withDegrees from 'core/libs/hoc/withDegrees';
import OnboardingHint from 'modules/common/OnboardingHint';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {compose, withProps, withPropsOnChange} from 'recompose';
import Tracks from '../components/Tracks';

const {PARTNER} = process.env;

const TracksContainer = (props) => (
  <>
    <div className="w-100 bg-darker-primary-blue tr-ns tc">
      <div className="w-80-ns w-90 center pv5 tr-ns tc white">
        <h4 className="f3 b pb4">
          <FormattedMessage id="tracks" />
        </h4>
        <h1 className="f4">
          <FormattedMessage id="tracks_description" />
        </h1>
      </div>
    </div>
    <Tracks {...props} />
    {PARTNER === 'BARMEJ' && <OnboardingHint />}
  </>
);

TracksContainer.propTypes = {
  publishedTracks: PropTypes.array.isRequired,
  soonTracks: PropTypes.array.isRequired,
  degrees: PropTypes.array.isRequired,
};

const mapStateToProps = ({auth, user}) => ({
  authenticated: auth.authenticated,
  showRecommendedTracks:
    auth.authenticated && !user.profile.data.hide_recommended_tracks,
  stepsProgress: user.progress.step || {},
  tracksProgress: user.progress.track || {},
});

const enhance = compose(
  connect(mapStateToProps),
  redirectIf(
    ({authenticated, isRedirect}) => authenticated && isRedirect,
    '/dashboard',
  ),
  withPropsOnChange(
    ['publishedTracks', 'soonTracks'],
    ({publishedTracks, soonTracks}) => {
      return {
        publishedTracks: publishedTracks
          .filter(({node: track}) => !track.soon)
          .map(({node: track}) => ({
            ...track,
            id: decodeId(track.id),
            duration: track.trackstepSet.edges.reduce(
              (acc, {node: {course}}) => {
                let courseDuration = 0;
                course.stageSet.edges.forEach(({node: stage}) => {
                  stage.stepSet.edges.forEach(({node: step}) => {
                    courseDuration += step.duration;
                  });
                });
                return acc + courseDuration;
              },
              0,
            ),
            trackstepSet: undefined,
            courses: track.trackstepSet.edges.map(({node: {course}}) => ({
              ...course,
              id: decodeId(course.id),
              stageSet: undefined,
              stages: course.stageSet.edges.map(({node: stage}) => ({
                ...stage,
                id: decodeId(stage.id),
                stepSet: undefined,
                steps: stage.stepSet.edges.map(({node: step}) => ({
                  ...step,
                  id: decodeId(step.id),
                })),
              })),
            })),
          })),
        soonTracks: soonTracks
          .filter(({node: track}) => track.soon)
          .map(({node: track}) => ({
            ...track,
            id: decodeId(track.id),
          })),
      };
    },
  ),
  //withDegrees,
  withProps(({degrees}) => {
    return {
      degrees:
        degrees &&
        degrees.edges &&
        degrees.edges.map(({node: degree}) => ({
          ...degree,
          id: decodeId(degree.id),
        })),
    };
  }),
);

export default enhance(TracksContainer);
