/* eslint-disable jsx-a11y/click-events-have-key-events */
import NewDesignButton from 'modules/common/NewDesignButton';
import Portals from 'modules/common/Portals';
import {postTrackInterest} from 'modules/tracks/actions';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';

class ComingSoonModal extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    id: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Portals>
        <div className="flex items-center justify-center fixed h-100 w-100 top-0 left-0 bg-black-90 z-9999">
          <div className="h-100 w-100" onClick={this.props.toggleModal} />
          <div className="w5 w3-ns w-50-m w-40-l w-80 absolute center-absolute br2 pa3 bg-white tr-ns tc">
            {!this.props.interest && this.props.status !== 'fetched' && (
              <div>
                <h1 className="tc f3-ns f4">
                  <FormattedMessage id="coming_soon.title" />
                </h1>
                <hr />
                <p className="pv3">
                  <FormattedMessage id="coming_soon.join_text" />
                </p>
                <p className="pb3 gray">{`"${this.props.title}"`}</p>
              </div>
            )}
            {this.props.interest && this.props.status !== 'fetched' && (
              <div>
                <p className="pv3">
                  <FormattedMessage id="coming_soon.already_joined" />
                </p>
                <p className="pb3 gray">{`"${this.props.title}"`}</p>
              </div>
            )}
            {this.props.status === 'fetched' && (
              <p className="pv3">
                <FormattedMessage id="coming_soon.joined_success" />
              </p>
            )}
            <div className="mt3 flex justify-center flex-row-ns flex-column">
              {!this.props.interest && this.props.status !== 'fetched' && (
                <NewDesignButton
                  className="pa3 bg-blue bg-animate hover-bg-dark-blue ml3-ns ml0 mb0-ns mb2"
                  onClick={() => {
                    this.props.dispatch(postTrackInterest(this.props.id));
                  }}
                  disabled={this.props.status === 'fetching'}
                >
                  <FormattedMessage id="coming_soon.join_yes" />
                </NewDesignButton>
              )}
              <NewDesignButton
                className="pa3 bg-green-light bg-animate hover-bg-green"
                onClick={this.props.toggleModal}
              >
                <FormattedMessage
                  id={
                    this.props.status === 'fetched' ? 'return_tracks' : 'cancel'
                  }
                />
              </NewDesignButton>
            </div>
          </div>
        </div>
      </Portals>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  state.tracks.trackInterestList[ownProps.id]
    ? {...state.tracks.trackInterestList[ownProps.id]}
    : {status: 'not fetched'};

export default connect(mapStateToProps)(ComingSoonModal);
