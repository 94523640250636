import {branch, renderComponent} from 'recompose';
import {navigate} from 'gatsby';

export default (
  shouldRedirect, // shouldRedirect: (props) => boolean
  destination = '/',
) =>
  branch(
    shouldRedirect,
    renderComponent(() => {
      navigate(destination);
      return null;
    }),
  );
