import Head from 'modules/common/Head';
import TracksWrapper from 'modules/common/TracksWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';
import RecommendedTrack from '../RecommendedTrack';
import ComingSoonTracksWrapper from './ComingSoonTracksWrapper';
import SUBSCRIPTION from '../../graphql/subscription.graphql';
import { useQuery } from '@apollo/client';

const {PARTNER} = process.env;

const Tracks = (props) => {
  const { data } = useQuery(SUBSCRIPTION);
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    authenticated,
    showRecommendedTracks,
    publishedTracks,
    tracksProgress,
    soonTracks,
    stepsProgress,
    degrees,
  } = props;
  const description =
    PARTNER === 'BARMEJ' ? 'tracks_description' : 'kfas_default_description';
  return (
    <>
      {showRecommendedTracks && PARTNER === 'BARMEJ' && (
        <RecommendedTrack
          tracks={publishedTracks}
          tracksProgress={tracksProgress}
        />
      )}
      <div className="bg-white pb5">
        <Head
          name={formatMessage({
            id: 'tracks',
          })}
          description={formatMessage({
            id: description,
          })}
          title={formatMessage({
            id:
              PARTNER === 'BARMEJ' ? 'helmet_tracks' : 'partner_helmet_tracks',
          })}
        />
        <TracksWrapper
          authenticated={authenticated}
          hasFilters
          stepsProgress={stepsProgress}
          title="available_tracks"
          tracks={publishedTracks}
          tracksProgress={tracksProgress}
          subscription={data?.recurrentPayment?.status}
        />
        {PARTNER === 'BARMEJ' && (
          <TracksWrapper
            authenticated={authenticated}
            title="available_nano_degrees"
            tracks={degrees}
            isDegreeBox
          />
        )}
        {PARTNER === 'BARMEJ' && (
          <ComingSoonTracksWrapper
            title="coming_soon_tracks"
            tracks={soonTracks}
            authenticated={authenticated}
          />
        )}
      </div>
    </>
  );
};

Tracks.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  showRecommendedTracks: PropTypes.bool.isRequired,
  publishedTracks: PropTypes.array.isRequired,
  tracksProgress: PropTypes.object.isRequired,
  soonTracks: PropTypes.array.isRequired,
  stepsProgress: PropTypes.object.isRequired,
  degrees: PropTypes.array.isRequired,
};

export default Tracks;
