import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ComingSoonModal from './ComingSoonModal';
import {heightComingSoonBox} from './styles.scss';

class ComingSoonTrackBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComingSoonModal: false,
    };
  }

  toggleComingSoonModal = () => {
    this.setState(({showComingSoonModal}) => ({
      showComingSoonModal: !showComingSoonModal,
    }));
  };

  render() {
    return (
      <div className="fr w-third-ns w-50-m w-50-mid w-100 pv4-ns pv3 ph3">
        <div
          className="br2 tc flex flex-column justify-between"
          style={{background: this.props.track.color}}
        >
          <div className={`items-center pa4 ${heightComingSoonBox}`}>
            <div className="flex flex-column justify-between">
              <h2 className="pt3 white pa0 pb3 f5 lh-copy">
                {this.props.track.name}
              </h2>
              <p
                className="ph3 white f6 pt2 pb3-ns"
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.track.description >
                    this.props.track.description.slice(0, 160)
                      ? `${this.props.track.description.slice(0, 115)}...`
                      : this.props.track.description,
                }}
              />
            </div>
          </div>
        </div>
        {this.state.showComingSoonModal && (
          <ComingSoonModal
            id={this.props.track.id}
            title={this.props.track.name}
            toggleModal={this.toggleComingSoonModal}
          />
        )}
      </div>
    );
  }
}

ComingSoonTrackBox.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.node.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    color: PropTypes.string,
  }).isRequired,
};

export default ComingSoonTrackBox;
