import {Mutation} from '@apollo/client/react/components';
import humanDuration from 'core/libs/helpers/humanDuration';
import CourseNextStepButton from 'modules/common/CourseNextStepButton';
import JOIN_TRACK from 'modules/common/graphql/joinTrack.graphql';
import Link from 'modules/common/Link';
import MetaItem from 'modules/common/MetaItem';
import NewDesignButton from 'modules/common/NewDesignButton';
import VideoModal from 'modules/common/VideoModal';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import reload from 'theme/assets/images/reload.svg';
import {pvButton, recommendedCard, recommendedCardFooter} from './styles.scss';

const {PARTNER} = process.env;

class RecommendedTrackBox extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    trailerOn: false,
  };

  toggleTrailer = () => {
    this.setState((state) => ({trailerOn: !state.trailerOn}));
  };

  render() {
    const {track, trackProgress} = this.props;
    return (
      <Mutation
        mutation={JOIN_TRACK}
        variables={{id: track.id, partner: PARTNER}}
      >
        {(mutate, {loading, error}) => {
          if (loading)
            return (
              <p>
                <FormattedMessage id="loading" />
              </p>
            );
          if (error)
            return (
              <p>
                <FormattedMessage id="server_error_try_again" />
              </p>
            );
          return (
            <div className={`bg-white pv4 br3 ph4 ${recommendedCard}`}>
              <div className="flex justify-between flex-row-ns flex-column">
                <div className="mb0-ns mb4 w-70-ns">
                  <div className="flex flex-row-ns flex-column">
                    <div className="tc">
                      {track.icon && (
                        <img
                          height="44"
                          width="44"
                          src={track.icon.url}
                          alt="track icon"
                        />
                      )}
                    </div>
                    <h4 className="mt3 ph1 f5-ns f6 mr2 tr-ns tc">
                      {track.name}
                    </h4>
                  </div>
                  <p
                    className="f5 light-silver tr-ns tc pv2 w-80-ns mr2-ns"
                    dangerouslySetInnerHTML={{
                      __html:
                        track.description > track.description.slice(0, 180)
                          ? `${track.description.slice(0, 155)}...`
                          : track.description,
                    }}
                  />
                </div>
                <div className="w-30-ns mt5-ns mb0-ns mb3 tc">
                  {(trackProgress === 'not started' ||
                    trackProgress === undefined) && (
                    <NewDesignButton
                      className="pv3 ph4 f6-ns f6 bg-blue bg-animate hover-bg-dark-blue pointer"
                      onClick={mutate}
                      link={`/${this.props.track.slug}`}
                    >
                      <FormattedMessage id="enroll_in" />
                    </NewDesignButton>
                  )}
                  {trackProgress === 'started' && (
                    <CourseNextStepButton
                      className="pv2 ph3 f6 bg-button-blue bg-animate hover-bg-dark-blue lh-copy"
                      trackSlug={track.slug}
                      courses={track.courses}
                      onClick={mutate}
                    >
                      <FormattedMessage id="continue_track" />
                    </CourseNextStepButton>
                  )}
                  {trackProgress === 'completed' && (
                    <CourseNextStepButton
                      className={`fw6 white dib no-underline tc br2 bg-green-light hover-bg-green bg-animate ${pvButton} ph3`}
                      trackSlug={track.slug}
                      courses={track.courses}
                      onClick={this.handleClick}
                    >
                      <FormattedMessage id="review_track" />
                      {'  '}
                      <img src={reload} alt="reload" />
                    </CourseNextStepButton>
                  )}
                </div>
              </div>
              <div
                className={`flex items-center justify-between flex-row-ns flex-column pv3 ${recommendedCardFooter}`}
              >
                <div className="flex items-center flex-row-ns flex-column mb0-ns mb4 w-70-ns">
                  <MetaItem className="black" border="b--black">
                    {track.courses.length > 10 ? (
                      <p className="black-80 f6 fw6">
                        {track.courses.length} <FormattedMessage id="course" />
                      </p>
                    ) : (
                      <p className="black-80 f6 fw6">
                        {track.courses.length} <FormattedMessage id="courses" />
                      </p>
                    )}
                  </MetaItem>
                  <MetaItem className="black-80 f6 fw6" border="b--black">
                    {humanDuration(track.duration)}
                  </MetaItem>
                  <MetaItem
                    className="black-80 f6 fw6"
                    border="b--black"
                    isLast
                  >
                    {track.level}
                  </MetaItem>
                </div>
                <div className="w-30-ns tc mr2-ns">
                  {track.trailerYoutubeId && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                      onClick={this.toggleTrailer}
                      className="f7 underline fw6 pv2 pointer color-blue-light"
                    >
                      <FormattedMessage id="view_trailer" />
                    </Link>
                  )}
                </div>
              </div>
              {this.state.trailerOn && (
                <VideoModal
                  rightButtonClick={this.toggleTrailer}
                  videoId={track.trailerYoutubeId}
                  title={track.name}
                />
              )}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

RecommendedTrackBox.propTypes = {
  track: PropTypes.object.isRequired,
  trackProgress: PropTypes.string,
};

export default RecommendedTrackBox;
