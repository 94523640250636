import React from 'react';
import PropTypes from 'prop-types';
import WelcomeWizard from 'modules/common/Svg/WelcomeWizard';
import TracksWizard from 'modules/common/Svg/TracksWizard';
import CommunityWizard from 'modules/common/Svg/CommunityWizard';
import LeaderboardWizard from 'modules/common/Svg/LeaderboardWizard';
import CertificateWizard from 'modules/common/Svg/CertificateWizard';

const OnboardingIcon = ({id}) => {
  switch (id) {
    case 0:
      return <WelcomeWizard />;
    case 1:
      return <TracksWizard />;
    case 2:
      return <CommunityWizard />;
    case 3:
      return <LeaderboardWizard />;
    case 4:
      return <CertificateWizard />;
    default:
      return null;
  }
};

OnboardingIcon.propTypes = {
  id: PropTypes.number,
};

const OnboardingIcons = ({id}) => {
  return (
    <div className="tc">
      <OnboardingIcon id={id} />
    </div>
  );
};

OnboardingIcons.propTypes = {
  id: PropTypes.number,
};

export default OnboardingIcons;
