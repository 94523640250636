/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ConfirmationModal from 'modules/common/ConfirmationModal';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

const HideRecommendedTracksPopup = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {
    confirmPopupHideRecommendedTracks,
    toggleConfirmPopupcHideRecommendedTracks,
    hideRecommendedTracksBox,
  } = props;
  return (
    <div className="tl-ns tc">
      {confirmPopupHideRecommendedTracks && (
        <ConfirmationModal
          toggleConfirmationPopup={toggleConfirmPopupcHideRecommendedTracks}
          title={formatMessage({
            id: 'hide_recommended_tracks_title_popup',
          })}
          dispatchAction={hideRecommendedTracksBox}
          confirmeMessage={formatMessage({
            id: 'hide_recommended_tracks_confirm',
          })}
          cancelMessage={formatMessage({
            id: 'hide_recommended_tracks_cancel',
          })}
        />
      )}
      <a
        onClick={toggleConfirmPopupcHideRecommendedTracks}
        className="underline pointer"
      >
        <FormattedMessage id="hide_recommended_tracks" />
      </a>
    </div>
  );
};

HideRecommendedTracksPopup.propTypes = {
  confirmPopupHideRecommendedTracks: PropTypes.bool,
  toggleConfirmPopupcHideRecommendedTracks: PropTypes.func,
  hideRecommendedTracksBox: PropTypes.func,
};

export default HideRecommendedTracksPopup;
