import React from 'react';

const CertificateWizard = () => (
  <svg
    width="280px"
    height="156px"
    viewBox="0 0 350 156"
    xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="#A17FEC" fillRule="nonzero">
        <path d="M41.09 53.011a9.553 9.553 0 0 0 9.577 9.584h46.488c4.353 0 7.922 3.572 7.922 7.929 0 4.356-3.569 7.928-7.922 7.928H16.454A12.173 12.173 0 0 0 4.266 90.65c0 6.709 5.397 12.198 12.188 12.198h38.653c5.31 0 9.663 4.27 9.663 9.671 0 5.315-4.266 9.671-9.663 9.671h-2.699c-9.228 0-16.628 7.493-16.628 16.642 0 9.235 7.487 16.641 16.628 16.641h203.539c6.094 0 10.969-4.879 10.969-10.978a10.928 10.928 0 0 0-10.97-10.978h-5.832c-4.614 0-8.445-3.747-8.445-8.451 0-4.618 3.744-8.452 8.445-8.452h45.792c6.442 0 11.665-5.227 11.665-11.675 0-6.447-5.223-11.675-11.665-11.675h-34.3a10.333 10.333 0 0 1-10.36-10.368c0-5.75 4.614-10.368 10.36-10.368h73.736c8.01 0 14.452-6.448 14.452-14.464a14.422 14.422 0 0 0-14.452-14.463H50.667c-5.31-.174-9.576 4.095-9.576 9.41z" />
        <ellipse cx="101.943" cy="4.655" rx="1" ry="1" />
        <ellipse cx="267.525" cy="125.675" rx="1" ry="1" />
        <ellipse cx="330.467" cy="87.165" rx="1" ry="1" />
        <ellipse cx="68.862" cy="72.266" rx="1" ry="1" />
        <ellipse cx="272.749" cy="85.074" rx="1" ry="1" />
        <ellipse cx="173.504" cy="24.956" rx="1.654" ry="1.655" />
        <ellipse cx="61.549" cy="24.956" rx="1.219" ry="1.22" />
        <ellipse cx="1.48" cy="124.194" rx="1.219" ry="1.22" />
        <ellipse cx="105.077" cy="31.055" rx="1.48" ry="1.481" />
        <path d="M192.57 2.477c-1.48.436-1.829.697-2.264 2.265-.435-1.48-.697-1.83-2.264-2.265 1.48-.436 1.829-.697 2.264-2.265.435 1.48.696 1.83 2.263 2.265zM322.545 117.05c-1.393.348-1.741.697-2.09 2.09-.348-1.393-.696-1.742-2.089-2.09 1.393-.349 1.741-.697 2.09-2.091.348 1.394.696 1.742 2.089 2.09zM50.319 115.22c-1.828.523-2.264.871-2.699 2.701-.522-1.83-.87-2.265-2.699-2.7 1.828-.524 2.264-.872 2.699-2.702.435 1.743.87 2.178 2.699 2.701zM296.515 81.328c-2.35.61-2.873 1.132-3.482 3.485-.61-2.353-1.132-2.876-3.482-3.485 2.35-.61 2.872-1.133 3.482-3.486.696 2.353 1.219 2.876 3.482 3.486zM252.203 19.206c-1.828.522-2.263.87-2.699 2.7-.522-1.83-.87-2.265-2.698-2.7 1.828-.523 2.263-.872 2.698-2.701.436 1.83.871 2.265 2.7 2.7zM31.776 60.591c-1.916.523-2.35.959-2.873 2.875-.523-1.916-.958-2.352-2.873-2.875 1.915-.523 2.35-.958 2.873-2.875.522 2.004.87 2.352 2.873 2.875z" />
      </g>
      <path
        d="M183.5 99.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V97c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
        fill="#FFDEDE"
        fillRule="nonzero"
      />
      <path
        d="M183.5 99.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V97c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M214 83.303L222.623 77v7.812c0 1.598-1.468 2.93-3.119 2.752L214 87.12v-3.817z"
        fill="#5A5856"
        fillRule="nonzero"
      />
      <path
        d="M222.623 77v7.634c0 1.776-1.468 3.285-3.394 3.285h-3.394L214 83.303 222.623 77z"
        stroke="#414042"
        strokeWidth="1.231"
      />
      <path
        d="M223.3 99.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V97c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
        fill="#FFDEDE"
        fillRule="nonzero"
      />
      <path
        d="M223.3 99.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V97c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M225.1 155.9v-18c0-11.5-10.9-20.9-24.3-20.9s-24.3 9.4-24.3 20.9v18h48.6z"
        fill="#E1B46C"
        fillRule="nonzero"
      />
      <path
        d="M177.2 132.9c-.5 1.6-.7 3.2-.7 4.9v18M183.4 123.3c-1.8 1.6-3.4 3.5-4.5 5.5M225.1 155.9v-18c0-11.5-10.9-20.9-24.3-20.9-5.1 0-9.8 1.4-13.8 3.7"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M201 126.7c-10.2 0-18.5-8.3-18.5-18.5V86.9c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v21.3c0 10.2-8.3 18.5-18.5 18.5z"
        fill="#383737"
        fillRule="nonzero"
      />
      <path
        d="M201 126.7c-10.2 0-18.5-8.3-18.5-18.5V86.9c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v21.3c0 10.2-8.3 18.5-18.5 18.5z"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M201 102.9c-8.6 0-17.5 6.9-17.2.1V90c0-8.6-.8-17.2 7.8-17.2h17.2c8.6 0 9.3 8.6 9.3 19.1v11c.1 6.4-5.2.5-17.1 0z"
        fill="#FFDEDE"
        fillRule="nonzero"
      />
      <path
        d="M220.1 101.5c0 9.4-9.4 2-18.8 2s-18.8 7.3-18.8-2"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M206.4 108.2c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4h10.8z"
        fill="#FFDEDE"
        fillRule="nonzero"
      />
      <path
        d="M206.4 108.2c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4h10.8zM195.5 92.7c-.6.6-1.4 1-2.4 1-.9 0-1.8-.4-2.4-1M206.9 93.2h3.7M198.5 91.6v8.3h7.6"
        stroke="#414042"
        strokeWidth="1.5"
      />
      <path
        d="M184.761 92.197c0-5.345 1.835-10.44 5.045-13.671l4.679-4.723 18.988 7.332c4.037 1.616 8.623-.248 11.008-4.97 2.294-4.6 2.569-11.932-5.87-22.868h-.092c.091 1.615 1.192 12.303-4.403 10.812 0 0-12.017-2.734-20.732-2.983-3.302-.124-5.963 3.48-5.963 7.954v.497h-3.302c-1.743 0-3.119 1.865-3.119 4.226v14.044c.092 2.485 1.56 4.35 3.394 4.35h.367z"
        fill="#5A5856"
        fillRule="nonzero"
      />
      <path
        d="M184.761 92.197c0-5.345 1.835-10.44 5.045-13.671l4.679-4.723 18.988 7.332c4.037 1.616 8.623-.248 11.008-4.97 2.294-4.6 2.569-11.932-5.87-22.868h-.092c.091 1.615 1.192 12.303-4.403 10.812 0 0-12.017-2.734-20.732-2.983-3.302-.124-5.963 3.48-5.963 7.954v.497h-3.302c-1.743 0-3.119 1.865-3.119 4.226v14.044c.092 2.485 1.56 4.35 3.394 4.35h.367z"
        stroke="#414042"
        strokeWidth="1.231"
      />
      <g transform="translate(185 122)" stroke="#414042" strokeWidth="1.5">
        <path d="M.7 18.1h9.8" />
        <circle cx="16.1" cy="19.5" r="1.6" />
        <circle cx="16.1" cy="27.4" r="1.6" />
        <path d="M15.8 15.9v-5.2L28.3 0M3.7 0l13.6 9.4" />
      </g>
      <g>
        <path
          d="M110.699 154.728a1.643 1.643 0 0 1-1.645-1.64v-45.093c0-.906.736-1.64 1.645-1.64h56.464c.909 0 1.645.734 1.645 1.64v45.092c0 .906-.736 1.641-1.645 1.641h-56.464"
          stroke="#414042"
          strokeWidth="1.3"
          fill="#D6A741"
        />
        <path
          d="M160.914 150.595h-43.967a3.745 3.745 0 0 0-3.75-3.74v-32.627a3.745 3.745 0 0 0 3.75-3.74h43.967a3.745 3.745 0 0 0 3.75 3.74v32.626a3.745 3.745 0 0 0-3.75 3.74"
          fill="#EFC86A"
        />
        <path
          fill="#BA8936"
          d="M148.825 132.61l-.021 15.662 3.453-3.43 3.442 3.441.021-15.664-6.895-.01"
        />
        <path
          fill="#EFC86A"
          d="M155.316 136.998l-.014 10.325-3.044-3.045-3.052 3.035.014-10.323h.412l-.013 9.33 2.64-2.624 2.63 2.633.013-9.331h.414"
        />
        <path
          d="M145.894 130.702a1.38 1.38 0 0 1-.44.983 9.645 9.645 0 0 0-.458.456 1.41 1.41 0 0 0-.28 1.528c.062.142.13.279.2.41.198.369.199.807.032 1.191a7.062 7.062 0 0 0-.065.155 1.394 1.394 0 0 0 .527 1.693l.058.038c.383.244.62.66.642 1.112v.019c.033.659.532 1.203 1.186 1.303l.08.011c.446.063.834.343 1.037.745l.031.061c.298.576.954.872 1.584.716l.069-.018a1.382 1.382 0 0 1 1.245.288l.061.052c.506.425 1.244.44 1.763.03l.015-.011a1.401 1.401 0 0 1 1.26-.251l.066.019a1.4 1.4 0 0 0 1.626-.72c.023-.044.044-.089.065-.133.184-.385.522-.678.939-.773.139-.032.28-.07.42-.113.54-.167.915-.654.97-1.216a9.24 9.24 0 0 0 .043-.733c.008-.381.17-.745.45-1.005.179-.166.352-.34.52-.521a1.403 1.403 0 0 0 .26-1.532 5.684 5.684 0 0 0-.196-.388 1.39 1.39 0 0 1-.049-1.213c.02-.045.038-.09.056-.137.245-.62.017-1.329-.547-1.688l-.058-.036a1.394 1.394 0 0 1-.654-1.105v-.019a1.393 1.393 0 0 0-1.2-1.289l-.08-.01a1.379 1.379 0 0 1-1.045-.734l-.032-.063a1.402 1.402 0 0 0-1.591-.699l-.067.018a1.382 1.382 0 0 1-1.249-.275l-.061-.05a1.398 1.398 0 0 0-1.764-.012l-.014.011a1.404 1.404 0 0 1-1.259.266l-.067-.018a1.4 1.4 0 0 0-1.616.738c-.025.05-.049.1-.072.151-.176.38-.504.673-.911.77a5.862 5.862 0 0 0-.44.126 1.414 1.414 0 0 0-.954 1.228 10.015 10.015 0 0 0-.035.645"
          fill="#BA8936"
        />
        <path
          d="M155.315 130.081a.727.727 0 0 0 .488-.341.729.729 0 0 0-1.068.205c.159.121.367.177.58.136"
          fill="#EFC86A"
        />
        <path
          d="M155.461 129.251c.041.212.17.384.343.487a.724.724 0 0 0-.206-1.066.724.724 0 0 0-.137.58M148.8 139.086a.724.724 0 0 1 .136-.579.724.724 0 0 1 .206 1.065.724.724 0 0 1-.342-.486"
          fill="#EFC86A"
        />
        <path
          d="M149.518 138.641a.727.727 0 0 1-.58-.136.727.727 0 0 1 1.067-.205.725.725 0 0 1-.487.341M155.972 130.65a.726.726 0 0 0 .54-.252.727.727 0 0 0-1.088.018.727.727 0 0 0 .548.235"
          fill="#EFC86A"
        />
        <path
          d="M156.26 129.858c.004.215.101.407.253.538a.723.723 0 0 0-.017-1.084.724.724 0 0 0-.236.546M147.992 138.395a.722.722 0 0 1 .235-.546.724.724 0 0 1 .017 1.084.724.724 0 0 1-.252-.538"
          fill="#EFC86A"
        />
        <path
          d="M148.776 138.083a.727.727 0 0 1-.547-.235.727.727 0 0 1 1.087-.018.726.726 0 0 1-.54.253M156.52 131.326a.727.727 0 0 0 .575-.155.726.726 0 0 0-1.074-.172.726.726 0 0 0 .499.327"
          fill="#EFC86A"
        />
        <path
          d="M156.942 130.595a.723.723 0 0 0 .155.573.725.725 0 0 0 .172-1.07.725.725 0 0 0-.327.497M147.317 137.574a.724.724 0 0 1 .326-.498.724.724 0 0 1-.171 1.071.724.724 0 0 1-.155-.573"
          fill="#EFC86A"
        />
        <path
          d="M148.144 137.402a.725.725 0 0 1-.499-.327.726.726 0 0 1 1.074.172.726.726 0 0 1-.575.155M156.941 132.085c.205.07.419.045.594-.053a.729.729 0 0 0-1.028-.355c.077.185.23.338.434.408"
          fill="#EFC86A"
        />
        <path
          d="M157.484 131.44a.724.724 0 0 0 .053.591.726.726 0 0 0 .356-1.025.725.725 0 0 0-.408.434M146.795 136.646a.724.724 0 0 1 .408-.432.724.724 0 0 1-.355 1.024.723.723 0 0 1-.053-.592"
          fill="#EFC86A"
        />
        <path
          d="M147.64 136.62a.727.727 0 0 1-.435-.408.729.729 0 0 1 1.028.355.728.728 0 0 1-.594.052M157.225 132.908a.728.728 0 0 0 .594.051.726.726 0 0 0-.95-.527.726.726 0 0 0 .356.476"
          fill="#EFC86A"
        />
        <path
          d="M157.872 132.366a.723.723 0 0 0-.051.593.726.726 0 0 0 .528-.948.726.726 0 0 0-.477.355M146.442 135.642a.726.726 0 0 1 .477-.356.729.729 0 0 1-.528.948.723.723 0 0 1 .05-.592"
          fill="#EFC86A"
        />
        <path
          d="M147.278 135.762a.726.726 0 0 1-.357-.476.728.728 0 0 1 .95.527.73.73 0 0 1-.593-.051M157.361 133.77a.728.728 0 0 0 .576.153.725.725 0 0 0-.844-.684c.009.2.1.394.268.53"
          fill="#EFC86A"
        />
        <path
          d="M158.093 133.348a.72.72 0 0 0-.154.574.727.727 0 0 0 .685-.842c-.2.01-.395.1-.531.268M146.269 134.59a.727.727 0 0 1 .532-.267.727.727 0 0 1-.685.842.722.722 0 0 1 .153-.574"
          fill="#EFC86A"
        />
        <path
          d="M147.072 134.853a.724.724 0 0 1-.269-.53.729.729 0 0 1 .845.683.73.73 0 0 1-.576-.153M157.346 134.642c.142.163.34.247.54.25a.728.728 0 0 0-.712-.82.725.725 0 0 0 .172.57"
          fill="#EFC86A"
        />
        <path
          d="M158.14 134.353a.723.723 0 0 0-.251.54.728.728 0 0 0 .821-.711.728.728 0 0 0-.57.171M146.281 133.524a.729.729 0 0 1 .57-.172.724.724 0 0 1-.821.71.725.725 0 0 1 .251-.538"
          fill="#EFC86A"
        />
        <path
          d="M147.026 133.922a.723.723 0 0 1-.172-.57.728.728 0 0 1 .712.82.728.728 0 0 1-.54-.25M157.18 135.5a.727.727 0 0 0 .488.34.724.724 0 0 0-.559-.931c-.06.19-.04.405.071.59"
          fill="#EFC86A"
        />
        <path
          d="M158.012 135.352a.727.727 0 0 0-.341.488.729.729 0 0 0 .933-.557.73.73 0 0 0-.592.07M146.478 132.475c.185-.11.4-.13.591-.07a.73.73 0 0 1-.933.557.724.724 0 0 1 .342-.487"
          fill="#EFC86A"
        />
        <path
          d="M147.142 132.996a.723.723 0 0 1-.07-.59.725.725 0 0 1 .559.93.726.726 0 0 1-.489-.34M156.868 136.315a.725.725 0 0 0 .422.42.723.723 0 0 0-.389-1.013.723.723 0 0 0-.033.593"
          fill="#EFC86A"
        />
        <path
          d="M157.713 136.315a.725.725 0 0 0-.42.421.728.728 0 0 0 1.015-.387.727.727 0 0 0-.595-.034M146.853 131.475a.728.728 0 0 1 .595.034.726.726 0 0 1-1.016.387.727.727 0 0 1 .421-.42"
          fill="#EFC86A"
        />
        <path
          d="M147.417 132.103a.724.724 0 0 1 .033-.593.726.726 0 0 1 .389 1.013.726.726 0 0 1-.422-.42M156.422 137.06c.04.211.17.384.342.486a.725.725 0 0 0-.206-1.065.723.723 0 0 0-.136.579"
          fill="#EFC86A"
        />
        <path
          d="M157.254 137.206a.726.726 0 0 0-.488.341.729.729 0 0 0 1.068-.205.728.728 0 0 0-.58-.136M147.394 130.561c.212-.04.42.015.58.136a.727.727 0 0 1-1.067.206.725.725 0 0 1 .487-.342"
          fill="#EFC86A"
        />
        <path
          d="M147.84 131.277a.724.724 0 0 1 .136-.578.725.725 0 0 1 .206 1.065.725.725 0 0 1-.342-.487M155.852 137.715c.003.215.1.408.252.538a.724.724 0 0 0-.017-1.084.724.724 0 0 0-.235.546"
          fill="#EFC86A"
        />
        <path
          d="M156.646 138.003a.727.727 0 0 0-.54.252.727.727 0 0 0 1.087-.017.727.727 0 0 0-.547-.235M148.087 129.756a.725.725 0 0 1 .547.234.727.727 0 0 1-1.087.018.725.725 0 0 1 .54-.252"
          fill="#EFC86A"
        />
        <path
          d="M148.4 130.538a.724.724 0 0 1 .236-.546.723.723 0 0 1 .017 1.084.725.725 0 0 1-.252-.538M155.175 138.261a.723.723 0 0 0 .155.574.725.725 0 0 0 .172-1.07.724.724 0 0 0-.327.496"
          fill="#EFC86A"
        />
        <path
          d="M155.907 138.683a.726.726 0 0 0-.575.155.726.726 0 0 0 1.073.17.726.726 0 0 0-.498-.325M148.91 129.082c.213.034.39.158.498.327a.727.727 0 0 1-1.073-.172.727.727 0 0 1 .575-.155"
          fill="#EFC86A"
        />
        <path
          d="M149.083 129.907a.725.725 0 0 1 .327-.497.723.723 0 0 1-.172 1.07.724.724 0 0 1-.155-.573M154.412 138.682a.723.723 0 0 0 .053.592.725.725 0 0 0 .356-1.025.726.726 0 0 0-.408.433"
          fill="#EFC86A"
        />
        <path
          d="M155.06 139.224a.728.728 0 0 0-.593.052.726.726 0 0 0 1.027.355.725.725 0 0 0-.434-.407M149.84 128.561c.204.07.356.223.434.407a.728.728 0 0 1-1.028-.354.729.729 0 0 1 .594-.053"
          fill="#EFC86A"
        />
        <path
          d="M149.867 129.404a.725.725 0 0 1 .408-.433.723.723 0 0 1-.356 1.024.722.722 0 0 1-.052-.591M153.587 138.965a.723.723 0 0 0-.05.592.729.729 0 0 0 .528-.948.726.726 0 0 0-.478.356"
          fill="#EFC86A"
        />
        <path
          d="M154.13 139.61a.728.728 0 0 0-.593-.05.726.726 0 0 0 .95.527.726.726 0 0 0-.357-.477M150.847 128.21c.189.104.312.28.356.476a.727.727 0 0 1-.95-.528.728.728 0 0 1 .594.051"
          fill="#EFC86A"
        />
        <path
          d="M150.727 129.043a.727.727 0 0 1 .477-.355.723.723 0 0 1-.528.948.723.723 0 0 1 .05-.593M152.724 139.1a.723.723 0 0 0-.154.575.728.728 0 0 0 .686-.842c-.2.01-.396.1-.532.268"
          fill="#EFC86A"
        />
        <path
          d="M153.147 139.83a.727.727 0 0 0-.576-.153.724.724 0 0 0 .844.684.723.723 0 0 0-.268-.53M151.9 128.037c.169.136.26.33.27.53a.725.725 0 0 1-.845-.683.727.727 0 0 1 .576.153"
          fill="#EFC86A"
        />
        <path
          d="M151.637 128.837a.726.726 0 0 1 .533-.267.723.723 0 0 1-.686.842.724.724 0 0 1 .154-.575M151.85 139.085a.723.723 0 0 0-.251.54.727.727 0 0 0 .82-.71.729.729 0 0 0-.57.17"
          fill="#EFC86A"
        />
        <path
          d="M152.138 139.877a.73.73 0 0 0-.54-.25.725.725 0 0 0 .712.82.722.722 0 0 0-.172-.57M152.97 128.049c.142.163.197.37.172.569a.727.727 0 0 1-.712-.82c.2.003.398.088.54.25"
          fill="#EFC86A"
        />
        <path
          d="M152.571 128.792a.728.728 0 0 1 .57-.172.728.728 0 0 1-.821.71.724.724 0 0 1 .251-.538M150.99 138.92a.726.726 0 0 0-.341.487.73.73 0 0 0 .933-.557.729.729 0 0 0-.592.07"
          fill="#EFC86A"
        />
        <path
          d="M151.137 139.75a.727.727 0 0 0-.489-.34.727.727 0 0 0 .559.93.723.723 0 0 0-.07-.59M154.022 128.245c.11.185.13.4.07.59a.726.726 0 0 1-.559-.93.726.726 0 0 1 .489.34"
          fill="#EFC86A"
        />
        <path
          d="M153.5 128.908c.185-.111.4-.13.592-.07a.725.725 0 0 1-.934.557.726.726 0 0 1 .342-.487M150.171 138.609a.725.725 0 0 0-.42.42.73.73 0 0 0 1.015-.387.73.73 0 0 0-.595-.033"
          fill="#EFC86A"
        />
        <path
          d="M150.172 139.451a.727.727 0 0 0-.422-.42.723.723 0 0 0 .388 1.014.723.723 0 0 0 .034-.594M155.024 128.62a.722.722 0 0 1-.034.593.726.726 0 0 1-.388-1.013.726.726 0 0 1 .422.42"
          fill="#EFC86A"
        />
        <path
          d="M154.394 129.182a.73.73 0 0 1 .596.033.726.726 0 0 1-1.016.388.726.726 0 0 1 .42-.421M150.311 132.077c.498-.245.892-.384 1.53-1.143h1.556v4.935h.813v1.16h-3.497v-1.16h.875v-3.234l-.875.41-.402-.968"
          fill="#EFC86A"
        />
        <path
          fill="#BA8936"
          d="M124.386 118.754h29.09v-3.505h-29.09zM130.745 122.434h16.372v-1.973h-16.372zM119.855 131.398h20.15v-1.972h-20.15zM119.855 141.475h20.15v-1.972h-20.15zM119.855 136.437h20.15v-1.973h-20.15z"
        />
      </g>
      <g>
        <path
          d="M106.791 91.343a11.281 11.281 0 0 1 11.334 11.324c0 6.29-5.037 11.323-11.334 11.323a11.281 11.281 0 0 1-11.333-11.323c0-6.291 5.151-11.324 11.333-11.324z"
          fill="#EFC86A"
        />
        <path
          d="M97.519 102.667c0-5.948 4.579-10.752 10.303-11.324h-1.03a11.281 11.281 0 0 0-11.334 11.324c0 6.29 5.037 11.323 11.333 11.323h1.03c-5.723-.572-10.302-5.376-10.302-11.323z"
          fill="#FFE4A3"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M105.074 106.784l-3.09-2.974.572-.571 2.518 2.402 5.61-5.605.572.572z"
        />
        <path
          d="M106.791 92.144a10.5 10.5 0 0 1 10.532 10.523 10.5 10.5 0 0 1-10.532 10.523 10.5 10.5 0 0 1-10.532-10.523c0-5.834 4.808-10.523 10.532-10.523zm0-.8a11.281 11.281 0 0 0-11.333 11.323c0 6.29 5.037 11.323 11.333 11.323a11.281 11.281 0 0 0 11.334-11.323c0-6.291-5.037-11.324-11.334-11.324z"
          fill="#333441"
          fillRule="nonzero"
        />
      </g>
      <path d="M.261 155.212h321.065" stroke="#A17FEC" strokeWidth="1.306" />
    </g>
  </svg>
);

export default CertificateWizard;
