import React from 'react';

const CommunityWizard = () => (
  <svg
    width="280px"
    height="156px"
    viewBox="0 0 350 156"
    xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <g fill="#8C9FEE" fillRule="nonzero">
        <path d="M41.09 53.011a9.553 9.553 0 0 0 9.577 9.584h46.488c4.353 0 7.922 3.572 7.922 7.929 0 4.356-3.569 7.928-7.922 7.928H16.454A12.173 12.173 0 0 0 4.266 90.65c0 6.709 5.397 12.198 12.188 12.198h38.653c5.31 0 9.663 4.27 9.663 9.671 0 5.315-4.266 9.671-9.663 9.671h-2.699c-9.228 0-16.628 7.493-16.628 16.642 0 9.235 7.487 16.641 16.628 16.641h203.539c6.094 0 10.969-4.879 10.969-10.978a10.928 10.928 0 0 0-10.97-10.978h-5.832c-4.614 0-8.445-3.747-8.445-8.451 0-4.618 3.744-8.452 8.445-8.452h45.792c6.442 0 11.665-5.227 11.665-11.675 0-6.447-5.223-11.675-11.665-11.675h-34.3a10.333 10.333 0 0 1-10.36-10.368c0-5.75 4.614-10.368 10.36-10.368h73.736c8.01 0 14.452-6.448 14.452-14.464a14.422 14.422 0 0 0-14.452-14.463H50.667c-5.31-.174-9.576 4.095-9.576 9.41z" />
        <ellipse cx="101.943" cy="4.655" rx="1" ry="1" />
        <ellipse cx="267.525" cy="125.675" rx="1" ry="1" />
        <ellipse cx="330.467" cy="87.165" rx="1" ry="1" />
        <ellipse cx="68.862" cy="72.266" rx="1" ry="1" />
        <ellipse cx="272.749" cy="85.074" rx="1" ry="1" />
        <ellipse cx="173.504" cy="24.956" rx="1.654" ry="1.655" />
        <ellipse cx="61.549" cy="24.956" rx="1.219" ry="1.22" />
        <ellipse cx="1.48" cy="124.194" rx="1.219" ry="1.22" />
        <ellipse cx="105.077" cy="31.055" rx="1.48" ry="1.481" />
        <path d="M192.57 2.477c-1.48.436-1.829.697-2.264 2.265-.435-1.48-.697-1.83-2.264-2.265 1.48-.436 1.829-.697 2.264-2.265.435 1.48.696 1.83 2.263 2.265zM322.545 117.05c-1.393.348-1.741.697-2.09 2.09-.348-1.393-.696-1.742-2.089-2.09 1.393-.349 1.741-.697 2.09-2.091.348 1.394.696 1.742 2.089 2.09zM50.319 115.22c-1.828.523-2.264.871-2.699 2.701-.522-1.83-.87-2.265-2.699-2.7 1.828-.524 2.264-.872 2.699-2.702.435 1.743.87 2.178 2.699 2.701zM296.515 81.328c-2.35.61-2.873 1.132-3.482 3.485-.61-2.353-1.132-2.876-3.482-3.485 2.35-.61 2.872-1.133 3.482-3.486.696 2.353 1.219 2.876 3.482 3.486zM252.203 19.206c-1.828.522-2.263.87-2.699 2.7-.522-1.83-.87-2.265-2.698-2.7 1.828-.523 2.263-.872 2.698-2.701.436 1.83.871 2.265 2.7 2.7zM31.776 60.591c-1.916.523-2.35.959-2.873 2.875-.523-1.916-.958-2.352-2.873-2.875 1.915-.523 2.35-.958 2.873-2.875.522 2.004.87 2.352 2.873 2.875z" />
      </g>
      <g transform="translate(104 102)">
        <path
          d="M31 53H2.1c-1.1 0-2-.9-2-2V2.6c0-1.1.9-2 2-2H31c1.1 0 2 .9 2 2V51c0 1.1-.9 2-2 2z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M11.4.7H30c1.6 0 2.9 1.3 2.9 2.9v46.5c0 1.6-1.3 2.9-2.9 2.9H3C1.4 53 .1 51.7.1 50.1V3.6C.1 2 1.4.7 3 .7h1.8M6.5.7h3.7M11.7 4.6h9.7"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <circle
          stroke="#414042"
          strokeWidth="1.5"
          cx="16.5"
          cy="48.8"
          r="1.6"
        />
        <path fill="#FFB936" fillRule="nonzero" d="M3.4 8.2h26.3v37.1H3.4z" />
      </g>
      <g transform="translate(148 66)">
        <path
          d="M7.5 33.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V31c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M7.5 33.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V31c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M47.3 33.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V31c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M47.3 33.2c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V31c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v2.2z"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M49.1 89.9v-18C49.1 60.4 38.2 51 24.8 51S.5 60.4.5 71.9v18h48.6z"
          fill="#62CA92"
          fillRule="nonzero"
        />
        <path
          d="M1.2 66.9c-.5 1.6-.7 3.2-.7 4.9v18M7.4 57.3c-1.8 1.6-3.4 3.5-4.5 5.5M49.1 89.9v-18C49.1 60.4 38.2 51 24.8 51c-5.1 0-9.8 1.4-13.8 3.7"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M25 60.7c-10.2 0-18.5-8.3-18.5-18.5V20.9C6.5 10.7 14.8 2.4 25 2.4s18.5 8.3 18.5 18.5v21.3c0 10.2-8.3 18.5-18.5 18.5z"
          fill="#383737"
          fillRule="nonzero"
        />
        <path
          d="M25 60.7c-10.2 0-18.5-8.3-18.5-18.5V20.9C6.5 10.7 14.8 2.4 25 2.4s18.5 8.3 18.5 18.5v21.3c0 10.2-8.3 18.5-18.5 18.5z"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M25 36.9c-8.6 0-17.5 6.9-17.2.1V24c0-8.6-.8-17.2 7.8-17.2h17.2c8.6 0 9.3 8.6 9.3 19.1v11c.1 6.4-5.2.5-17.1 0z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M44.1 35.5c0 9.4-9.4 2-18.8 2s-18.8 7.3-18.8-2"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M30.4 42.2c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4h10.8z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M30.4 42.2c0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4h10.8z"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M6.3 28.2c-2.5 0-4.5-2-4.5-4.5V12.9c0-3.7 3-6.7 6.7-6.7C8.5 3.1 11 .7 14 .7h27.3c3.7 0 6.7 3 6.7 6.7v16.2c0 2.4-2 4.3-4.4 4.2V17s-3.1 5-12.8 5c-11.7 0-19.4-5.8-19.4-5.8s-.7 1.6-2.3 3c-1.4 1.3-2.9.8-2.9.8v8.2h.1z"
          fill="#5A5856"
          fillRule="nonzero"
        />
        <path
          d="M43.6 17v10.7c2.4.1 4.4-1.8 4.4-4.2V7.3c0-3.7-3-6.7-6.7-6.7H14c-3.1 0-5.5 2.5-5.5 5.5-3.7 0-6.7 3-6.7 6.7v10.8c0 2.5 2 4.5 4.5 4.5V20s1.5.5 2.9-.8c1.6-1.4 2.3-3 2.3-3"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <path
          d="M8.9 14.4s10.3 7.6 22 7.6c9.7 0 14.1-6.7 14.1-6.7M19.5 26.7c-.6.6-1.4 1-2.4 1-.9 0-1.8-.4-2.4-1M30.9 27.2h3.7M22.5 25.6v8.3h7.6M9.7 74.1h9.8"
          stroke="#414042"
          strokeWidth="1.5"
        />
        <circle
          stroke="#414042"
          strokeWidth="1.5"
          cx="25.1"
          cy="75.5"
          r="1.6"
        />
        <circle
          stroke="#414042"
          strokeWidth="1.5"
          cx="25.1"
          cy="83.4"
          r="1.6"
        />
        <path
          stroke="#414042"
          strokeWidth="1.5"
          d="M24.8 71.9v-5.2L37.3 56M12.7 56l13.6 9.4"
        />
      </g>
      <path
        d="M205.178 68.8v14.58c0 .81.622 1.44 1.422 1.44h.8v8.46c0 .27.356.45.533.18l7.2-8.73H235.4c.8 0 1.422-.63 1.422-1.44V68.8c0-.81-.622-1.44-1.422-1.44h-28.8c-.8 0-1.422.63-1.422 1.44z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M205.178 68.8v14.58c0 .81.622 1.44 1.422 1.44h.8v8.46c0 .27.356.45.533.18l7.2-8.73H235.4c.8 0 1.422-.63 1.422-1.44V68.8c0-.81-.622-1.44-1.422-1.44h-28.8c-.8 0-1.422.63-1.422 1.44zM212.2 72.04h17.6M212.2 75.91h17.6M212.2 79.78h9.511"
        stroke="#414042"
        strokeWidth="1.333"
      />
      <g>
        <path
          d="M113.861 79.4v11.34c0 .63-.486 1.12-1.111 1.12h-.625v6.58c0 .21-.278.35-.417.14l-5.625-6.79H90.25c-.625 0-1.111-.49-1.111-1.12V79.4c0-.63.486-1.12 1.111-1.12h22.5c.625 0 1.111.49 1.111 1.12z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M113.861 79.4v11.34c0 .63-.486 1.12-1.111 1.12h-.625v6.58c0 .21-.278.35-.417.14l-5.625-6.79H90.25c-.625 0-1.111-.49-1.111-1.12V79.4c0-.63.486-1.12 1.111-1.12h22.5c.625 0 1.111.49 1.111 1.12zM108.375 81.92h-13.75M108.375 84.93h-13.75M108.375 87.94h-7.43"
          stroke="#414042"
          strokeWidth="1.042"
        />
      </g>
      <path d="M.261 155.212h321.065" stroke="#8C9FEE" strokeWidth="1.306" />
    </g>
  </svg>
);

export default CommunityWizard;
