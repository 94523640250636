import React from 'react';
import PropTypes from 'prop-types';

const SliderArrow = ({
  width = '15px',
  color = '#000',
  right = false,
  className = '',
}) => {
  return (
    <svg
      className={`${className} ${right ? 'rotate-180' : ''}`}
      width={width}
      viewBox="0 0 15 28"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.119 27.474c.19.191.429.287.69.287.262 0 .5-.096.691-.287a.986.986 0 0 0 0-1.389L2.357 13.88 14.5 1.675a.986.986 0 0 0 0-1.388.974.974 0 0 0-1.381 0L.286 13.187a.986.986 0 0 0 0 1.387l12.833 12.9z"
        fill={color}
      />
    </svg>
  );
};

SliderArrow.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  right: PropTypes.bool,
};

export default SliderArrow;
