import log from 'core/libs/logger';
import SliderArrow from 'modules/common/Svg/SliderArrow';
import {updateUserProfile} from 'modules/user/actions/profile';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {
  branch,
  compose,
  renderNothing,
  setPropTypes,
  withPropsOnChange,
  withStateHandlers,
} from 'recompose';
import HideRecommendedTracksPopup from './HideRecommendedTracksPopup';
import RecommendedTrackBox from './RecommendedTrackBox';
import {arrow} from './styles.scss';
import TrackLine from './TrackLine';

const RecommendedTrack = ({
  currentSelectedTrackIndex,
  handleChangeTrack,
  tracksProgress,
  recommendedTracks,
  user,
  nextTrackIndex,
  prevTrackIndex,
  hideRecommendedTracksBox,
  confirmPopupHideRecommendedTracks,
  toggleConfirmPopupcHideRecommendedTracks,
}) => {
  const currentSelectedTrack = recommendedTracks[currentSelectedTrackIndex];
  const currentSelectedTrackProgress =
    currentSelectedTrack && tracksProgress[currentSelectedTrack.id];
  return (
    <div className="w-100 bg-near-white pt4-ns pb5-ns pv3">
      <div className="w-80-ns w-90 center mt4 mt0-ns">
        <HideRecommendedTracksPopup
          confirmPopupHideRecommendedTracks={confirmPopupHideRecommendedTracks}
          toggleConfirmPopupcHideRecommendedTracks={
            toggleConfirmPopupcHideRecommendedTracks
          }
          hideRecommendedTracksBox={hideRecommendedTracksBox}
        />
        <div className="pv2">
          <h5 className="f5-ns f5 tr-ns tc black mv4 pb2">
            <FormattedMessage id="welcome_only" /> {user.first_name}!{' '}
            <FormattedMessage id="tracks_recommended_notice" />
          </h5>
          <TrackLine
            handleChangeTrack={handleChangeTrack}
            recommendedTracks={recommendedTracks}
            tracksProgress={tracksProgress}
            currentIndex={currentSelectedTrackIndex}
          />
          <div className="mt3 flex flex-row-reverse items-center">
            <button
              aria-label="Next track index"
              onClick={nextTrackIndex}
              className={`mr2 mr3-ns pa0 bw0 bg-transparent outline-0 ${
                currentSelectedTrackIndex < recommendedTracks.length - 1
                  ? 'pointer dim'
                  : 'o-30'
              }`}
              type="button"
            >
              <SliderArrow className={`dib v-mid ${arrow}`} />
            </button>
            <div className="flex1">
              <RecommendedTrackBox
                track={currentSelectedTrack}
                trackProgress={currentSelectedTrackProgress}
              />
            </div>
            <button
              aria-label="Prev track index"
              onClick={prevTrackIndex}
              className={`ml2 ml3-ns pa0 bw0 bg-transparent outline-0 ${
                currentSelectedTrackIndex > 0 ? 'pointer dim' : 'o-30'
              }`}
              type="button"
            >
              <SliderArrow right className={`dib v-mid ${arrow}`} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RecommendedTrack.propTypes = {
  confirmPopupHideRecommendedTracks: PropTypes.bool.isRequired,
  currentSelectedTrackIndex: PropTypes.number.isRequired,
  handleChangeTrack: PropTypes.func.isRequired,
  nextTrackIndex: PropTypes.func.isRequired,
  prevTrackIndex: PropTypes.func.isRequired,
  recommendedTracks: PropTypes.array.isRequired,
  toggleConfirmPopupcHideRecommendedTracks: PropTypes.func.isRequired,
  tracksProgress: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hideRecommendedTracksBox: PropTypes.func.isRequired,
};

const enhance = compose(
  setPropTypes({
    tracks: PropTypes.array.isRequired,
    tracksProgress: PropTypes.object.isRequired,
  }),
  connect(
    ({user}) => ({
      user: user.profile.data,
    }),
    (dispatch) => ({
      hideRecommendedTracksBox: () => {
        dispatch(
          updateUserProfile({hide_recommended_tracks: true}, {hasNotif: false}),
        );
      },
    }),
  ),
  branch(({user}) => user.selected_tracks === undefined, renderNothing),
  withPropsOnChange(['tracks', 'user'], ({tracks, user}) => {
    let selectedTracks;
    try {
      if (user.selected_tracks === '[-1]') {
        selectedTracks = [1049, 1];
      } else {
        selectedTracks = JSON.parse(user.selected_tracks);
      }
    } catch (e) {
      console.error('Cannot parse selected tracks', {
        selectedTracks: user.selected_tracks,
      });
    }
    if (!Array.isArray(selectedTracks) || selectedTracks.length === 0)
      return {};
    return {
      recommendedTracks: selectedTracks
        .map((selectedTrack) => tracks.find(({id}) => id === selectedTrack))
        .sort((track1, track2) => (track1.position > track2.position ? 1 : -1))
        .filter((track, index) => {
          if (track === undefined || track.id === undefined) {
            log('We have wrong id in selected tracks !', {
              index,
              selectedTracks,
              track,
              currentUrl: typeof window !== 'undefined' && window.location.href,
            });
            return false;
          }
          return true;
        }),
    };
  }),
  branch(
    ({recommendedTracks}) =>
      !recommendedTracks || recommendedTracks.length === 0,
    renderNothing,
  ),
  withStateHandlers(
    {currentSelectedTrackIndex: 0, confirmPopupHideRecommendedTracks: false},
    {
      handleChangeTrack: () => (e) => ({
        currentSelectedTrackIndex: parseInt(e.target.name, 10),
      }),
      nextTrackIndex: (
        {currentSelectedTrackIndex},
        {recommendedTracks: {length}},
      ) => () => {
        return {
          currentSelectedTrackIndex: Math.min(
            currentSelectedTrackIndex + 1,
            length - 1,
          ),
        };
      },
      prevTrackIndex: ({currentSelectedTrackIndex}) => () => {
        return {
          currentSelectedTrackIndex: Math.max(currentSelectedTrackIndex - 1, 0),
        };
      },
      toggleConfirmPopupcHideRecommendedTracks: ({
        confirmPopupHideRecommendedTracks,
      }) => () => {
        return {
          confirmPopupHideRecommendedTracks: !confirmPopupHideRecommendedTracks,
        };
      },
    },
  ),
);

export default enhance(RecommendedTrack);
