import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import TracksContainer from 'modules/tracks/containers/TracksContainer';

const TracksPage = ({
  data: {
    fugoki: {allTracks, allDegrees},
  },
  location,
}) => (
  <TracksContainer
    isRedirect={location.search && location.search.includes('redirect')}
    publishedTracks={allTracks.edges}
    soonTracks={allTracks.edges}
    degrees={allDegrees}
  />
);

TracksPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default TracksPage;

export const pageQuery = graphql`
  query tracksQuery($partner: String!) {
    fugoki {
      allTracks(partner: $partner) {
        edges {
          node {
            id
            name
            description
            position
            level
            icon {
              url
            }
            soon
            status
            slug
            trailerYoutubeId
            color
            topic {
              name
            }
            trackstepSet {
              edges {
                node {
                  id
                  course {
                    id
                    slug
                    name
                    stageSet {
                      edges {
                        node {
                          id
                          name
                          slug
                          stepSet {
                            edges {
                              node {
                                id
                                isLocked
                                slug
                                duration
                                status
                                videotutorial {
                                  id
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allDegrees(partner: $partner) {
        edges {
          node {
            id
            slug
            name
            description
            durationInMonths
            landingPageUrl
            level
            icon {
              url
            }
            cohortSet {
              edges {
                node {
                  id
                  active
                  startDate
                }
              }
            }
          }
        }
      }
    }
  }
`;
